import React from 'react';
import { Link } from 'react-router-dom';
import {
    NoticeItem,
    NoticeTitle,
    NoticeLabel,
    MyHeader,
    ListColumn,
    Container,
    ContainerPadding,
    FlxMiddleMb15,
    Fz20Bold,
} from '@/css/style';
import { useNoticeListReadQuery } from '@/services/boardService';
import { format, parse } from 'date-fns';

function NoticeList() {
    const { data: noticeListData } = useNoticeListReadQuery();
    return (
        <Container className="main-container">
            {/* <MyHeader>
                <Fz20Bold>공지사항</Fz20Bold>
            </MyHeader> */}
            <ContainerPadding>
                <ListColumn>
                    {noticeListData?.data.docs.map((items) => (
                        <NoticeItem to={`/notice/${items.id}`}>
                            <FlxMiddleMb15>
                                <NoticeLabel>공지</NoticeLabel>
                                <NoticeTitle>{items.title}</NoticeTitle>
                            </FlxMiddleMb15>
                            {format(
                                parse(items.created, "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date()),
                                'yyyy. MM. dd'
                            )}
                        </NoticeItem>
                    ))}
                </ListColumn>
            </ContainerPadding>
        </Container>
    );
}
export default NoticeList;
