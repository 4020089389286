import React, { useEffect, useState } from 'react';
import 'react-day-picker/dist/style.css';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';

export default function RsvDayPicker(props) {
    const formatCaption = (date) => {
        const y = format(date, 'yyyy', { locale: ko });
        const m = format(date, 'LL', { locale: ko });
        return `${y}. ${m}`;
    };

    return (
        <>
            <style>{calendarStyle}</style>
            {props.mode === 'range' && <style>{rangeStyles}</style>}
            {props.mode === 'single' && <style>{singleStyle}</style>}
            <DayPicker
                mode={props.mode}
                selected={props.selected}
                onSelect={props.onSelect}
                onMonthChange={props.onMonthChange}
                modifiers={props.modifiers}
                modifiersStyles={props.modifiersStyles}
                modifiersClassNames={props.modifiersClassNames}
                formatters={{ formatCaption }}
                disabled={props.disabled}
                // footer={<p>{selected && format(selected, 'PP')}</p>}
                locale={ko}
                defaultMonth={props.defaultMonth}
            />
        </>
    );
}

const calendarStyle = `
        .rdp{
            --rdp-cell-size: 14.286%;
            // --rdp-cell-size: 3.077rem;
            --rdp-caption-font-size: 1.362rem; /* Font size for the caption labels. */
            --rdp-accent-color: #00BF9D; /* Accent color for the background of selected days. */
            --rdp-background-color: #fff; /* Background color for the hovered/focused elements. */
            --rdp-accent-color-dark: ##00BF9D; /* Accent color for the background of selected days (to use in dark-mode). */
            --rdp-background-color-dark: ##00BF9D; /* Background color for the hovered/focused elements (to use in dark-mode). */
            margin:0;
            --rdp-outline-selected: none;
            --rdp-outline: none;
        }
        .rdp-months, .rdp-month, .rdp-table {width: 100%;}
        .rdp-head_cell {height:3.38rem; font-size:1.154rem;font-weight:normal;color:#1C1B1F;}
        .rdp-caption {position:relative;margin-bottom:1.538rem;justify-content:center;color:#1C1B1F;}
        .rdp-nav_button_previous {left:6.25rem;}
        .rdp-nav_button_next {right:6.25rem;}
        .rdp-cell, .rdp-weeknumber, .rdp-day {height:4.23077rem;}
        .rdp-day{padding-top:0.615rem;align-items:flex-start;color:#1C1B1F;font-size:1rem;}
        .rdp-cell {padding:0 1.5%;}
        // .rdp-cell {padding:0 2.5%;}
        .rdp-weeknumber, .rdp-day {width:100%;max-width:55px;border:0;border-radius:4.23077rem;}
        .rdp-day_today:not(.rdp-day_outside) {font-weight:inherit;color:var(--rdp-accent-color);}
        .rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover, .rdp-day_today.rdp-day_selected {color:#fff;font-weight:700;}
        .rdp-day_selected {padding-top:0;align-items:center;}
        .rdp-nav_button{position:absolute;top:50%;-moz-transform:translateY(-50%);-webkit-transform:translateY(-50%);transform:translateY(-50%);width:unset;height:unset;}
        .rdp-nav_button svg, .rdp-nav_button path {fill: #A2A2A2}
        .offday {
            position:relative;
            padding-top: 0;
            align-items: center;
            border: 1px solid #ff3062;
            background-color: #ffebeb;
            color: transparent;
        }
        .offday::before {
            content:"휴";
            position:absolute;
            top:50%;
            left:50%;
            color:#ff3062;
            font-weight:700;
            transform:translate(-50%,-50%);
        }
        .offday.workday {
            padding-top:0.615rem;
            border:0;
            align-items:flex-start;
            background:none;
            font-weight:400;
            color:#1C1B1F;
        }
        .offday.rdp-day_today {color:transparent;}
        .offday.workday.rdp-day_today {color:#00BF9D;}
        // .rdp-day_today:not(.rdp-day_outside).offday {color:transparent;}
        .offday.workday::before{content:"";}
        .offday.workday.rdp-day_selected, .offday.workday.rdp-day_selected:focus-visible, .offday.workday.rdp-day_selected:hover {background:none;}
    `;
const rangeStyles = `
        .rdp-cell:has(.rdp-day_selected ),
        .rdp-day_selected {
            border-radius:0;
            background-color:#E5F6F3;
            font-weight:400;
            color:#1C1B1F;
        }
        .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start),
        .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
            border-radius: 4.23077rem !important;
            background-color:var(--rdp-accent-color);
            font-weight:700;
            color:#fff;
        }
        .rdp-cell:has(.rdp-day_range_start),
        .rdp-cell:has(.rdp-day_range_end) {background-color:transparent;}
        .rdp-day.rdp-day_range_start,
        .rdp-day.rdp-day_range_end {padding-top:0;align-items:center;font-weight:700;}
        .rdp-day_today:not(.rdp-day_outside) {color:var(--rdp-accent-color)}
        .rdp-day_range_end.rdp-day_range_start {border-radius: 4.23077rem !important;}
    `;
const singleStyle = `
    .rdp-cell {padding:2.5px 1.5%;};
    .rdp-day_today:not(.rdp-day_outside).offday {color:transparent;}
`;
