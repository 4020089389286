import { create } from 'zustand';

const initialState = {
    category: {
        showDesigner: true,
        showShop: false,
        showDealer: false,
    },
    keyword: {
        designer: '',
        shop: '',
        dealer: '',
    },
    status: {
        designer: '',
        shop: '',
        dealer: '',
    },
};

const useHomeStore = create((set) => ({
    ...initialState,
    toggleCategory: (select) => {
        set((state) => ({
            category: {
                showShop: select === 'showShop',
                showDesigner: select === 'showDesigner',
                showDealer: select === 'showDealer',
            },
        }));
    },
    setKeyword: (screen, text) => {
        set((state) => ({
            keyword: {
                ...state.keyword,
                [screen]: text,
            },
        }));
    },
    setStatus: (screen, status) => {
        set((state) => ({
            status: {
                ...state.keyword,
                [screen]: status,
            },
        }));
    },
    terminate: () => {
        set({
            ...initialState,
            keyword: {
                designer: '',
                shop: '',
                dealer: '',
            },
            status: {
                designer: '',
                shop: '',
                dealer: '',
            },
        });
    },
}));

export default useHomeStore;
