import { create } from 'zustand';

const initialState = {
    fields: {
        name: '',
        tel: '',
        address: '',
        address_detail: '',
        homepage: '',
        introduce: '',
        reservation_notice: '',
        note: '',
        services: '',
        email: '',
        opening_hour: [],
        naverblog: '',
        instagram: '',
        youtube: '',
        facebook: '',
        kakaochannel: '',
        daumcafe: '',
        manager_id: '',
        naver_id: '',
        naver_key: '',
        approved: false,
    },

    modal: {
        address: false,
    },
    timeTable: [
        '00:00',
        '00:30',
        '01:00',
        '01:30',
        '02:00',
        '02:30',
        '03:00',
        '03:30',
        '04:00',
        '04:30',
        '05:00',
        '05:30',
        '06:00',
        '06:30',
        '07:00',
        '07:30',
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
        '20:30',
        '21:00',
        '21:30',
        '22:00',
        '22:30',
        '23:00',
        '23:30',
    ],
    dayList: [
        {
            day: 'monday',
            label: '월',
        },
        {
            day: 'tuesday',
            label: '화',
        },
        {
            day: 'wednesday',
            label: '수',
        },
        {
            day: 'thursday',
            label: '목',
        },
        {
            day: 'friday',
            label: '금',
        },
        {
            day: 'saturday',
            label: '토',
        },
        {
            day: 'sunday',
            label: '일',
        },
    ],
    opening_hour_set: {
        monday: 'open',
        monday_from_time: '10:00',
        monday_to_time: '20:00',
        tuesday: 'open',
        tuesday_from_time: '10:00',
        tuesday_to_time: '20:00',
        wednesday: 'open',
        wednesday_from_time: '10:00',
        wednesday_to_time: '20:00',
        thursday: 'open',
        thursday_from_time: '10:00',
        thursday_to_time: '20:00',
        friday: 'open',
        friday_from_time: '10:00',
        friday_to_time: '20:00',
        saturday: 'open',
        saturday_from_time: '10:00',
        saturday_to_time: '20:00',
        sunday: 'open',
        sunday_from_time: '10:00',
        sunday_to_time: '20:00',
    },
    file: {
        main: null,
        sub: [],
    },
    isSubmit: false,
};

const useShopStore = create((set) => ({
    ...initialState,
    setField: (obj) => {
        set((state) => ({
            fields: {
                ...state.fields,
                ...obj,
            },
        }));
    },
    setOpeningHour: (obj) => {
        set((state) => ({
            opening_hour_set: {
                ...state.opening_hour_set,
                ...obj,
            },
        }));
    },
    setFieldOpeningHour: (obj) => {
        set((state) => {
            console.log('setFieldOpeningHour - state:', [
                ...state.fields.opening_hour,
                ...obj.opening_hour,
            ]);
            return {
                fields: {
                    ...state.fields,
                    opening_hour: [...state.fields.opening_hour, ...obj.opening_hour],
                },
            };
        });
    },
    setFile: (type, data) => {
        set((state) => {
            let updatedFile = { ...state.file };

            if (type === 'main') {
                updatedFile = {
                    ...updatedFile,
                    main: data,
                };
            } else if (type === 'sub') {
                updatedFile = {
                    ...updatedFile,
                    sub: [...updatedFile.sub, ...data],
                };
            }

            return {
                file: updatedFile,
            };
        });
    },
    setIsSubmit: (boolean) => {
        set({ isSubmit: boolean });
    },
    toggleModal: (select) => {
        set((state) => ({
            modal: {
                [select]: !state.modal[select],
            },
        }));
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useShopStore;
