import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Container,
    ItemDesigner,
    DesignerInfo,
    DesignerName,
    RoundProfile,
    ListColumn,
    Fz15Bold,
    LineCenter,
    FullImg,
    ListCol2,
    ContainerPadding,
    Spacing36,
    Flxbt,
    BtnHalfModal,
    Mb10,
    BtnLikeInImg,
    FlxbtFull,
    DesignerRates,
    Mb20,
    ColAccent,
    Fz15,
    ColBlack,
    Divider,
    HomeNoticeBox,
    FlxGap10,
    Spacing20,
    BtnToggleAccent,
    DesignerPrices,
    PriceItem,
    Fz16Medium,
    TxRight,
    OrgPrice,
    ConsumerPrice,
    Fz18Medium,
    LinkFlxbtFull,
    Mb30,
    MembStatus,
    MembStatusGreen,
    MembStatusRed,
    MembStatusPurple,
    FlxMiddleMb30,
    TabAccent,
    Gap10,
    FlxbtFullMb30,
    Fz18Bold,
    JoinField,
    InputWrapper,
    JoinInputPlc15,
    BtnInInputRound,
    Col3Grid,
    ChkAndTxt,
    TabWrapper,
    TabAccent2,
    StickyBtmCenter,
    BtnLogin,
    ShopItem,
    DealerItem,
    Spacing10,
} from '@/css/style';
import config from '@config';
import { FaStar } from 'react-icons/fa';
import { useIntersectionObserver } from '../../utils/helper';
import useHomeStore from '@/stores/common/home';
import useShowStore from '@/stores/common/show';
import useUserStore from '@/stores/user/user';
import Chkbox from '@/components/Chkbox';
import NoContent from '@/components/NoContent';
import altimg from '@/assets/img/altimg.png';
import arrowDownThinGray from '@/assets/img/arrow_down_thin_gray.svg';
import icReview from '@/assets/img/ic_review.svg';
import icRsvUser from '@/assets/img/ic_rsv_user.svg';
import {
    useGetDealerQuery,
    useGetDesignerQuery,
    useGetShopQuery,
} from '../../services/managerService';
import { format, parse } from 'date-fns';
import { useNoticeListReadQuery } from '../../services/boardService';
import { useMyQuery } from '../../services/authService';
import { useDealerDeleteMutation } from '../../services/dealerService';
import { toast } from 'react-toastify';
import { useShopDeleteMutation } from '../../services/shopService';

function List() {
    const hs = useHomeStore();
    const us = useUserStore();
    const ss = useShowStore();
    const designerRef = useRef(null);
    const shopRef = useRef(null);
    const dealerRef = useRef(null);
    const navigate = useNavigate();
    const [delShopRequestId, setDelShopRequestId] = useState([]);
    const [delDealerRequestId, setDelDealerRequestId] = useState([]);

    const handleShopDelete = () => {};

    const handleCheckboxChange = (itemId, type) => {
        if (type === 'shop') {
            setDelShopRequestId((prevState) => {
                if (prevState === itemId) {
                    return null;
                } else {
                    return itemId;
                }
            });
        } else if (type === 'dealer') {
            setDelDealerRequestId((prevState) => {
                if (prevState === itemId) {
                    return null;
                } else {
                    return itemId;
                }
            });
        }
    };

    const {
        data: designerData,
        isLoading: designerDataLoading,
        refetch: designerDataRefetch,
        fetchNextPage: designerDataFetchNextPage,
        hasNextPage: designerDataHasNextPage,
        isFetchingNextPage: designerDataIsFetchingNextPage,
    } = useGetDesignerQuery({
        text: hs.keyword.designer,
        status: hs.status.designer,
    });
    const {
        data: shopData,
        isLoading: shopDataLoading,
        refetch: shopDataRefetch,
        fetchNextPage: shopDataFetchNextPage,
        hasNextPage: shopDataHasNextPage,
        isFetchingNextPage: shopDataIsFetchingNextPage,
    } = useGetShopQuery({
        name: hs.keyword.shop,
        // status: hs.status.shop,
        approved: hs.status.shop,
    });
    const {
        data: dealerData,
        isLoading: dealerDataLoading,
        refetch: dealerDataRefetch,
        fetchNextPage: dealerDataFetchNextPage,
        hasNextPage: dealerDataHasNextPage,
        isFetchingNextPage: dealerDataIsFetchingNextPage,
    } = useGetDealerQuery(
        {
            text: hs.keyword.dealer,
        },
        us.user.id
    );
    const { data: noticeListData } = useNoticeListReadQuery();
    const { data: myData, isLoading } = useMyQuery();
    const DealerDeleteMutation = useDealerDeleteMutation(dealerDataRefetch);
    const ShopDeleteMutation = useShopDeleteMutation(shopDataRefetch);

    const observerDesigner = useIntersectionObserver(
        designerDataFetchNextPage,
        designerRef,
        designerData,
        designerDataIsFetchingNextPage
    );
    const observerShop = useIntersectionObserver(
        shopDataFetchNextPage,
        shopRef,
        shopData,
        shopDataIsFetchingNextPage
    );
    const observerDealer = useIntersectionObserver(
        dealerDataFetchNextPage,
        dealerRef,
        dealerData,
        dealerDataIsFetchingNextPage
    );
    useEffect(() => {
        return () => {
            if (observerDesigner.current) {
                observerDesigner.current.disconnect();
            }
            if (observerShop.current) {
                observerShop.current.disconnect();
            }
            if (observerDealer.current) {
                observerDealer.current.disconnect();
            }
        };
    }, [observerDesigner, observerShop, observerDealer]);

    useEffect(() => {
        designerDataLoading || shopDataLoading || dealerDataLoading
            ? ss.setLottie(true)
            : ss.setLottie(false);
    }, [designerDataLoading, shopDataLoading, dealerDataLoading]);

    console.log('shopData', designerData);
    if (!isLoading) {
        return (
            <Container className="main-container">
                <ContainerPadding>
                    <Spacing20 />
                    <Fz15Bold as={LinkFlxbtFull} to="/notice">
                        <ColAccent>공지사항</ColAccent>
                        <span>더보기+</span>
                    </Fz15Bold>
                    <HomeNoticeBox>
                        <ul>
                            {noticeListData?.data.docs.slice(0, 2).map((items) => (
                                <FlxbtFull as="li" key={items.id}>
                                    <ColBlack as={Link} to={`/notice/${items.id}`}>
                                        {items.title}
                                    </ColBlack>
                                    <span>
                                        {format(
                                            parse(
                                                items.created,
                                                "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                                new Date()
                                            ),
                                            'yyyy. MM. dd'
                                        )}
                                    </span>
                                </FlxbtFull>
                            ))}
                        </ul>
                    </HomeNoticeBox>
                </ContainerPadding>
                <Divider $mb="2.3077rem" />
                <ContainerPadding>
                    <FlxGap10 as={Mb20}>
                        <img src={icRsvUser} />
                        <Fz18Medium>
                            <strong>{myData?.data.name}</strong> 매니저님 환영합니다.
                        </Fz18Medium>
                    </FlxGap10>
                    {/* <FlxGap10 as={Mb30}>
                    <Fz15Bold $col="#00BF9D">대리점 코드</Fz15Bold>
                    <Fz15 $col="#000">CP1245</Fz15>
                </FlxGap10> */}
                    <FlxMiddleMb30>
                        <TabAccent
                            $active={hs.category.showDesigner}
                            onClick={() => hs.toggleCategory('showDesigner')}
                        >
                            관리 디자이너
                        </TabAccent>
                        <Gap10 />
                        <TabAccent
                            $active={hs.category.showShop}
                            onClick={() => hs.toggleCategory('showShop')}
                        >
                            관리 매장
                        </TabAccent>
                        <Gap10 />
                        <TabAccent
                            $active={hs.category.showDealer}
                            onClick={() => hs.toggleCategory('showDealer')}
                        >
                            관리 딜러
                        </TabAccent>
                    </FlxMiddleMb30>
                    <div
                        data-tab="designer"
                        style={{
                            display: hs.category.showDesigner ? 'block' : 'none',
                            paddingTop: '1rem',
                        }}
                    >
                        <FlxbtFullMb30>
                            <Fz18Bold>관리 디자이너 정보</Fz18Bold>
                            <Fz15Bold $col="#000">
                                <ColAccent>{designerData?.pages[0].data.totalDocs}</ColAccent> 건
                            </Fz15Bold>
                        </FlxbtFullMb30>
                        <JoinField $mb="1.538rem">
                            <InputWrapper>
                                <JoinInputPlc15
                                    type="text"
                                    placeholder="디자이너 이름을 검색해 주세요"
                                    value={hs.keyword.designer}
                                    onChange={(e) => hs.setKeyword('designer', e.target.value)}
                                />
                                <BtnInInputRound onClick={designerDataRefetch}>
                                    검색
                                </BtnInInputRound>
                            </InputWrapper>
                        </JoinField>
                        <TabWrapper $mb="2.3077rem">
                            <TabAccent2
                                $active={hs.status.designer === ''}
                                onClick={() => hs.setStatus('designer', '')}
                            >
                                전체
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={hs.status.designer === 'approved'}
                                onClick={() => hs.setStatus('designer', 'approved')}
                            >
                                승인
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={hs.status.designer === 'review'}
                                onClick={() => hs.setStatus('designer', 'review')}
                            >
                                심사
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={hs.status.designer === 'subscription'}
                                onClick={() => hs.setStatus('designer', 'subscription')}
                            >
                                라이센스
                            </TabAccent2>
                        </TabWrapper>
                        {/* <Col3Grid as={Mb30}>
                        <ChkAndTxt htmlFor="designerAll">
                            <Chkbox
                                id="designerAll"
                                checked={hs.status.designer === ''}
                                onChange={() => hs.setStatus('designer', '')}
                                value={''}
                            />
                            <Fz15 $col="#000">전체</Fz15>
                        </ChkAndTxt>
                        <ChkAndTxt htmlFor="designerApproved">
                            <Chkbox
                                id="designerApproved"
                                checked={hs.status.designer === 'approved'}
                                onChange={() => hs.setStatus('designer', 'approved')}
                                value={'approved'}
                            />
                            <Fz15 $col="#000">승인</Fz15>
                        </ChkAndTxt>
                        <ChkAndTxt htmlFor="designerReview">
                            <Chkbox
                                id="designerReview"
                                checked={hs.status.designer === 'review'}
                                onChange={() => hs.setStatus('designer', 'review')}
                                value={'review'}
                            />
                            <Fz15 $col="#000">심사</Fz15>
                        </ChkAndTxt>

                        <ChkAndTxt htmlFor="designerPaid">
                            <Chkbox
                                id="designerPaid"
                                checked={hs.status.designer === 'paid'}
                                onChange={() => hs.setStatus('designer', 'paid')}
                                value={'paid'}
                            />
                            <Fz15 $col="#000">라이센스</Fz15>
                        </ChkAndTxt>
                    </Col3Grid> */}
                        <ListColumn>
                            {!designerDataLoading &&
                                !(
                                    designerData?.pages &&
                                    designerData?.pages[0].data.docs.length > 0
                                ) && <NoContent message="검색된 디자이너가 없습니다." />}
                            {designerData?.pages.map((page) =>
                                page.data.docs
                                    .filter((items) => !items.deleted)
                                    .map((items) => (
                                        <ItemDesigner key={items._id} $mb="2.3077rem">
                                            <RoundProfile>
                                                <FullImg
                                                    src={`
                                                ${config.DESIGNER_IMG_URL}${items.profileImage?.filename}?size=100`}
                                                    onError={(e) => {
                                                        e.currentTarget.src = altimg;
                                                    }}
                                                    onClick={() =>
                                                        navigate(`/designers/${items._id}`)
                                                    }
                                                    alt="프로필 이미지"
                                                />
                                            </RoundProfile>
                                            <DesignerInfo>
                                                <Flxbt as={Mb10}>
                                                    <strong>{items.shop?.name}</strong>
                                                    <div>
                                                        {items.subscription ? (<>
                                                            <MembStatusGreen>라이센스</MembStatusGreen>
                                                            {
                                                                items.actived ? 
                                                                <MembStatusGreen>판매</MembStatusGreen>
                                                                : 
                                                                <MembStatusPurple>숨김</MembStatusPurple>    
                                                            }
                                                            </>
                                                        ) : items.approved ? (
                                                            <MembStatusGreen>승인</MembStatusGreen>
                                                        ) : !items.approved ? (
                                                            <MembStatusPurple>심사</MembStatusPurple>
                                                        ) : null}
                                                    </div>
                                                </Flxbt>
                                                <Flxbt as={Mb10}>
                                                    <DesignerName
                                                        onClick={() => {
                                                            navigate(`/designer/${items._id}`);
                                                        }}
                                                    >
                                                        {items.title}
                                                    </DesignerName>
                                                </Flxbt>
                                                {/* <FlxMiddleMb10>
                                            <ProfileTag>뿌리염색</ProfileTag>
                                            <ProfileTag>다운펌</ProfileTag>
                                            <ProfileTag>전체염색</ProfileTag>
                                            <ProfileTag>드라이</ProfileTag>+
                                        </FlxMiddleMb10> */}
                                                <FlxbtFull>
                                                    <DesignerRates $ml="0">
                                                        <img src={icReview} alt="후기" />
                                                        <strong>{items.ratingCount}</strong>
                                                        <FaStar
                                                            color="#FFBB00"
                                                            style={{ marginLeft: '0.77rem' }}
                                                        />
                                                        <strong>
                                                            {items.rating
                                                                ? items.averageRating.toFixed(1)
                                                                : 0}
                                                        </strong>
                                                    </DesignerRates>
                                                </FlxbtFull>
                                            </DesignerInfo>
                                        </ItemDesigner>
                                    ))
                            )}
                        </ListColumn>

                        {designerDataHasNextPage && (
                            <LineCenter ref={designerRef}>
                                <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                                <img src={arrowDownThinGray} alt="more" />
                            </LineCenter>
                        )}
                        <Spacing36 />
                    </div>
                    <div
                        data-tab="shop"
                        style={{
                            display: hs.category.showShop ? 'block' : 'none',
                            paddingTop: '1rem',
                        }}
                    >
                        {' '}
                        <FlxbtFullMb30>
                            <Fz18Bold>관리 매장 정보</Fz18Bold>
                            <Fz15Bold $col="#000">
                                <ColAccent>{shopData?.pages[0].data.totalDocs}</ColAccent> 건
                            </Fz15Bold>
                        </FlxbtFullMb30>
                        <JoinField $mb="1.538rem">
                            <InputWrapper>
                                <JoinInputPlc15
                                    type="text"
                                    placeholder="매장 이름을 검색해 주세요"
                                    value={hs.keyword.shop}
                                    onChange={(e) => hs.setKeyword('shop', e.target.value)}
                                />
                                <BtnInInputRound onClick={shopDataRefetch}>검색</BtnInInputRound>
                            </InputWrapper>
                        </JoinField>
                        <TabWrapper $mb="2.3077rem">
                            <TabAccent2
                                $active={hs.status.shop === ''}
                                onClick={() => hs.setStatus('shop', '')}
                            >
                                전체
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={hs.status.shop === false}
                                onClick={() => hs.setStatus('shop', false)}
                            >
                                승인요청
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={hs.status.shop === true}
                                onClick={() => hs.setStatus('shop', true)}
                            >
                                승인완료
                            </TabAccent2>
                        </TabWrapper>
                        {/* <Col3Grid as={Mb30}>
                        <ChkAndTxt htmlFor="shopAll">
                            <Chkbox
                                id="shopAll"
                                checked={hs.status.shop === ''}
                                onChange={() => hs.setStatus('shop', '')}
                                value={''}
                            />
                            <Fz15 $col="#000">전체</Fz15>
                        </ChkAndTxt>
                        <ChkAndTxt htmlFor="shopDisapproved">
                            <Chkbox
                                id="shopDisapproved"
                                checked={hs.status.shop === false}
                                onChange={() => hs.setStatus('shop', false)}
                                value={'disapproved'}
                            />
                            <Fz15 $col="#000">승인요청</Fz15>
                        </ChkAndTxt>
                        <ChkAndTxt htmlFor="shopApproved">
                            <Chkbox
                                id="shopApproved"
                                checked={hs.status.shop === true}
                                onChange={() => hs.setStatus('shop', true)}
                                value={'approved'}
                            />
                            <Fz15 $col="#000">승인완료</Fz15>
                        </ChkAndTxt>
                    </Col3Grid> */}
                        <ListColumn>
                            {!shopDataLoading &&
                                !(shopData?.pages && shopData?.pages[0].data.docs.length > 0) && (
                                    <NoContent message="검색된 매장이 없습니다." />
                                )}
                            {shopData?.pages.map((page) =>
                                page.data.docs.map((items) => (
                                    <React.Fragment key={items.id}>
                                        <ShopItem>
                                            <div>
                                                <RoundProfile>
                                                    <FullImg
                                                        src={`
                                            ${config.SHOP_IMG_URL}${items.main_image?.filename}?size=100`}
                                                        alt="매장 이미지"
                                                        onError={(e) => {
                                                            e.currentTarget.src = altimg;
                                                        }}
                                                    />
                                                    <Chkbox
                                                        id={items.id}
                                                        value={items.id}
                                                        onChange={() => {
                                                            if (!items.designer_count) {
                                                                handleCheckboxChange(
                                                                    items.id,
                                                                    'shop'
                                                                );
                                                            } else {
                                                                toast.error(
                                                                    '소속 디자이너가 없는 매장만 삭제할 수 있습니다.'
                                                                );
                                                            }
                                                        }}
                                                        checked={delShopRequestId === items.id}
                                                    />
                                                </RoundProfile>
                                                <Spacing10 />
                                                {items.approved ? (
                                                    <MembStatusGreen>승인완료</MembStatusGreen>
                                                ) : !items.approved ? (
                                                    <MembStatusPurple>승인요청</MembStatusPurple>
                                                ) : null}
                                            </div>
                                            <ul>
                                                <Fz16Medium
                                                    as="li"
                                                    $mb="1.154rem"
                                                    $col="#000"
                                                    onClick={() =>
                                                        navigate(`/shops/${items.id}/edit`)
                                                    }
                                                >
                                                    {items.name}
                                                </Fz16Medium>
                                                <Mb10 as="li">
                                                    <i>매장주소</i>
                                                    <span>
                                                        {items.address + ' ' + items.address_detail}
                                                    </span>
                                                </Mb10>
                                                <Mb10 as="li">
                                                    <i>전화번호</i>
                                                    <span>{items.tel}</span>
                                                </Mb10>
                                                <Mb10 as="li">
                                                    <i>디자이너</i>
                                                    <span>{items.designer_count}인</span>
                                                </Mb10>
                                                <Mb10 as="li">
                                                    <i>등록일</i>
                                                    <span>
                                                        {format(
                                                            parse(
                                                                items.created,
                                                                "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                                                new Date()
                                                            ),
                                                            'yyyy. MM. dd'
                                                        )}
                                                    </span>
                                                </Mb10>
                                            </ul>
                                        </ShopItem>
                                    </React.Fragment>
                                ))
                            )}
                        </ListColumn>
                        {shopDataHasNextPage && (
                            <LineCenter ref={shopRef}>
                                <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                                <img src={arrowDownThinGray} alt="more" />
                            </LineCenter>
                        )}
                        <StickyBtmCenter>
                            <ListCol2>
                                <BtnHalfModal
                                    style={{ backgroundColor: '#000' }}
                                    onClick={() => {
                                        ShopDeleteMutation.mutate(delShopRequestId);
                                    }}
                                >
                                    선택 삭제
                                </BtnHalfModal>
                                <BtnHalfModal
                                    style={{ backgroundColor: '#FF3062' }}
                                    onClick={() => navigate('/shops/new')}
                                >
                                    신규 등록
                                </BtnHalfModal>
                            </ListCol2>
                        </StickyBtmCenter>
                    </div>
                    <div
                        data-tab="dealer"
                        style={{
                            display: hs.category.showDealer ? 'block' : 'none',
                            paddingTop: '1rem',
                        }}
                    >
                        <FlxbtFullMb30>
                            <Fz18Bold>관리 딜러 정보</Fz18Bold>
                            <Fz15Bold $col="#000">
                                <ColAccent>{dealerData?.pages[0].data.totalDocs}</ColAccent> 건
                            </Fz15Bold>
                        </FlxbtFullMb30>
                        <JoinField $mb="1.538rem">
                            <InputWrapper>
                                <JoinInputPlc15
                                    type="text"
                                    placeholder="딜러 이름을 검색해 주세요"
                                    value={hs.keyword.dealer}
                                    onChange={(e) => hs.setKeyword('dealer', e.target.value)}
                                />
                                <BtnInInputRound onClick={dealerDataRefetch}>검색</BtnInInputRound>
                            </InputWrapper>
                        </JoinField>
                        {/* <TabWrapper $mb="2.3077rem">
                        <TabAccent2
                            $active={hs.status.shop === ''}
                            onClick={() => hs.setStatus('shop', '')}
                        >
                            전체
                        </TabAccent2>
                        ・
                        <TabAccent2
                            $active={hs.status.shop === false}
                            onClick={() => hs.setStatus('shop', false)}
                        >
                            승인요청
                        </TabAccent2>
                        ・
                        <TabAccent2
                            $active={hs.status.shop === true}
                            onClick={() => hs.setStatus('shop', true)}
                        >
                            승인완료
                        </TabAccent2>
                    </TabWrapper> */}
                        <ListColumn>
                            {!dealerDataLoading &&
                                !(
                                    dealerData?.pages && dealerData?.pages[0].data.docs.length > 0
                                ) && <NoContent message="검색된 딜러가 없습니다." />}
                            {dealerData?.pages.map((page) =>
                                page.data.docs.map((items) => (
                                    <React.Fragment key={items._id}>
                                        <DealerItem>
                                            <Chkbox
                                                id={items.id}
                                                value={items.id}
                                                onChange={() => {
                                                    if (
                                                        !items.activeDesignerCount ||
                                                        !items.inactiveDesignerCount
                                                    ) {
                                                        handleCheckboxChange(items._id, 'dealer');
                                                    } else {
                                                        toast.error(
                                                            '소속 디자이너가 없는 딜러만 삭제할 수 있습니다.'
                                                        );
                                                    }
                                                }}
                                                checked={delDealerRequestId === items._id}
                                            />
                                            <ul>
                                                <Fz16Medium
                                                    as="li"
                                                    $mb="1.154rem"
                                                    $col="#000"
                                                    onClick={() =>
                                                        navigate(`/dealers/${items._id}/edit`)
                                                    }
                                                >
                                                    {items.name}
                                                </Fz16Medium>
                                                <Mb10 as="li">
                                                    <i>이름</i>
                                                    <span>{items.name}</span>
                                                </Mb10>
                                                <Mb10 as="li">
                                                    <i>연락처</i>
                                                    <span>{items.phone}</span>
                                                </Mb10>
                                                <Mb10 as="li">
                                                    <i>딜러 코드</i>
                                                    <span>{items.referer_code}</span>
                                                </Mb10>
                                                <Mb10 as="li">
                                                    <i>노출 / 숨김</i>
                                                    <span>
                                                        {items.activeDesignerCount} /{' '}
                                                        {items.inactiveDesignerCount}{' '}
                                                    </span>
                                                </Mb10>
                                                <Mb10 as="li">
                                                    <i>등록일</i>
                                                    <span>
                                                        {format(
                                                            parse(
                                                                items.created,
                                                                "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                                                new Date()
                                                            ),
                                                            'yyyy. MM. dd'
                                                        )}
                                                    </span>
                                                </Mb10>
                                            </ul>
                                        </DealerItem>
                                    </React.Fragment>
                                ))
                            )}
                        </ListColumn>
                        {dealerDataHasNextPage && (
                            <LineCenter ref={dealerRef}>
                                <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                                <img src={arrowDownThinGray} alt="more" />
                            </LineCenter>
                        )}
                        <StickyBtmCenter>
                            <ListCol2>
                                <BtnHalfModal
                                    style={{ backgroundColor: '#000' }}
                                    onClick={() =>
                                        DealerDeleteMutation.mutate({
                                            dealer: delDealerRequestId,
                                            manager: us.user.id,
                                        })
                                    }
                                >
                                    선택 삭제
                                </BtnHalfModal>
                                <BtnHalfModal
                                    style={{ backgroundColor: '#FF3062' }}
                                    onClick={() => navigate('/dealers/new')}
                                    disabled={DealerDeleteMutation.isPending}
                                >
                                    신규 등록
                                </BtnHalfModal>
                            </ListCol2>
                        </StickyBtmCenter>
                    </div>
                </ContainerPadding>
            </Container>
        );
    }
}

export default List;
