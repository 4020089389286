import useUserStore from '@/stores/user/user';
import { Navigate } from 'react-router-dom';

function ManagerRoute(props) {
    const us = useUserStore();

    if (us.user.user_id && us.user.role === 'MANAGER') {
        return props.children;
    } else {
        return <Navigate replace to="/login" />;
    }
}

export default ManagerRoute;
