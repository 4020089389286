import { instance } from '@services/api';
import { useQuery, useMutation, useInfiniteQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const dealerService = {
    dealerCreate: (data) => {
        return instance.post(`/managers/${data.manager}/dealers`, data);
    },
    dealerUpdate: (data) => {
        return instance.put(`/managers/${data.manager}/dealers/${data.dealer}`, data);
    },
    dealerDelete: (data) => {
        return instance.delete(`/managers/${data.manager}/dealers/${data.dealer}`, data);
    },
    getDealers: (data) => {
        console.log('getDealers', data);
        return instance.get(`/managers/${data.manager}/dealers`, { params: data });
    },
    getDealer: (data) => {
        return instance.get(`/managers/${data.manager}/dealers/${data.dealer}`);
    },
};

export const useDealerCreateMutation = () => {
    const navigate = useNavigate();
    return useMutation({
        mutationFn: async (obj) => {
            const response = await dealerService.dealerCreate(obj);
            return response;
        },
        onSuccess: (res) => {
            toast.success('딜러가 추가되었습니다.');
            navigate('/', { replace: true });
        },
        onError: (error) => {
            toast.error(error.response.data.message);
        },
    });
};

export const useDealerUpdateMutation = () => {
    const navigate = useNavigate();
    return useMutation({
        mutationFn: async (obj) => {
            const response = await dealerService.dealerUpdate(obj);
            return response;
        },
        onSuccess: (res) => {
            toast.success('딜러가 수정되었습니다.');
            navigate('/', { replace: true });
        },
        onError: (error) => {
            toast.error(error.response.data.message);
        },
    });
};

export const useDealerDeleteMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const response = await dealerService.dealerDelete(obj);
            return response;
        },
        onSuccess: (res) => {
            toast.success('딜러가 삭제되었습니다.');
            onSuccess(res);
        },
        onError: (error) => {
            toast.error(error.message);
        },
    });
};

export const useGetDealersQuery = (data) => {
    return useQuery({
        queryKey: ['getDealers', data.manager],
        queryFn: () => dealerService.getDealers(data),
    });
}

export const useGetDealerQuery = (data) => {
    return useQuery({
        queryKey: ['getDealer', data.dealer],
        queryFn: () => dealerService.getDealer(data),
    });
};
