import { create } from 'zustand';

const initialState = {
    screen: {
        calculate: true,
        history: false,
    },
    status: '',
    modal: {
        period: false,
    },
};

const useNumbersStore = create((set) => ({
    ...initialState,
    setScreen: (select) => {
        set((state) => ({
            screen: {
                calculate: select === 'calculate',
                history: select === 'history',
            },
        }));
    },
    setStatus: (status) => {
        set((state) => ({
            ...state.status,
            status: status,
        }));
    },
    toggleModal: (select) => {
        set((state) => ({
            modal: {
                [select]: !state.modal[select],
            },
        }));
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useNumbersStore;
