import { create } from 'zustand';

const initialState = {
    showTopNavBar: true,
    isLogo: false,
    isRightMenu: true,
    barTitle: '',
    showAside: false,
    showGnbSearch: false,
    lottie: false,
};

const useShowStore = create((set) => ({
    ...initialState,
    setshowTopNavBar: (boolean) => {
        set({ showTopNavBar: boolean });
    },

    setIsLogo: (boolean) => {
        set({ isLogo: boolean });
    },

    setIsRightMenu: (boolean) => {
        set({ isRightMenu: boolean });
    },
    setShowAside: (boolean) => {
        set({ showAside: boolean });
    },
    setBarTitle: (text) => {
        set({ barTitle: text });
    },
    setLottie: (boolean) => {
        set({ lottie: boolean });
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useShowStore;
