const API_SERVER_HOST = process.env.REACT_APP_API_HOST;
const config = {
    API_SERVER_HOST,
    REVIEW_IMG_URL: `${API_SERVER_HOST}/files/review/`,
    DESIGNER_IMG_URL: `${API_SERVER_HOST}/files/designer/`,
    SHOP_IMG_URL: `${API_SERVER_HOST}/files/shop/`,
    ADVERTISEMENT_IMG_URL: `${API_SERVER_HOST}/files/advertisement/`,
};

export default config;
