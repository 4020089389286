import React, { useState } from 'react';
import {
    PopOver,
    BtnCloseModal,
    Fz18Bold,
    TermPadding,
    MyHeader,
    Paragraph,
    Container,
    ContainerPadding,
    Fz20Bold,
    NavHeight,
    TermItem2,
} from '@/css/style';
import arrowLeft from '@/assets/img/arrow_left_thin_bl.svg';
import arrowRightThinGray from '@/assets/img/arrow_right_thin_gray.svg';

function TermList() {
    const [popOvers, setPopOvers] = useState([
        {
            title: '만 14세 이상입니다.',
            content:
                '개인정보 수집 동의\n1. 기본수집항목: [필수] 이름, (휴대)전화번호, [선택] 이메일 주소※ 추가 수집하는 필수항목- 예약자와 실제 방문자가 다른 경우 : 방문자의 이름 및 휴대전화번호- 배송, 방문 등이 필요한 상품 구매 시 : 주소- 해외 여행 관련 상품 구매 시 : 여권상 영문명, 여권번호 끝 4자리, 성별, 생년월일, 이메일주소, 카카오톡ID, 동행 아동정보(여권상 영문명, 생년월일, 신장)- 병원을 이용하는 경우: 생년월일 (병원 재진 시 이전 진료기록 조회를 위해 예약자명, 생년월일, 전화번호가 수집될 수 있습니다.)- 자동차 검사소 예약 시 : 차량번호, [선택](사회적약자 수수료 감면 대상인 경우)기초생활대상자, 국가유공자, 한부모가족, 다자녀, 교통사고지원가족, 교통안전의인 중 해당 여부2. 수집 및 이용목적 : 사업자회원과 예약이용자의 원활한 거래 진행, 고객상담, 불만처리 등 민원 처리, 분쟁조정 해결을 위한 기록보존3. 보관기간- 회원탈퇴 시 지체없이 파기- 단, 자동차 검사소 예약시 선택항목은 별도 저장하지 않음- 단, 관련 법령에 의하여 일정 기간 보관이 필요한 경우에는 해당 기간 동안 보관함4. 동의 거부권 등에 대한 고지: 정보주체는 개인정보의 수집 및 이용 동의를 거부할 권리가 있으나, 이 경우 상품 및 서비스 예약이 제한될 수 있습니다. 그 밖의 내용은 네이버 개인정보 처리방침을 따릅니다.',
            isVisible: false,
        },
        {
            title: '머리할래 이용약관',
            content: 'Content for PopOver 2',
            isVisible: false,
        },
        {
            title: '개인정보 수집 동의',
            content: 'Content for PopOver 3',
            isVisible: false,
        },
        {
            title: '위치기반 서비스 이용약관',
            content: 'Content for PopOver 4',
            isVisible: false,
        },
    ]);

    const togglePopOver = (index) => {
        const updatedPopOvers = [...popOvers];
        updatedPopOvers[index].isVisible = !updatedPopOvers[index].isVisible;
        setPopOvers(updatedPopOvers);
    };
    return (
        <>
            <Container className="main-container">
                {/* <MyHeader>
                    <Fz20Bold>약관 내용 확인</Fz20Bold>
                </MyHeader> */}
                <ContainerPadding>
                    {/* 약관 리스트 */}
                    <ul>
                        {popOvers.map((popOver, index) => (
                            <TermItem2 key={index} onClick={() => togglePopOver(index)}>
                                {popOver.title}
                                <img src={arrowRightThinGray} alt="약관 보기" />
                            </TermItem2>
                        ))}
                    </ul>
                    {/* //약관 리스트 */}
                </ContainerPadding>
            </Container>
            {/* 약관 팝업 */}
            {popOvers.map((popOver, index) => (
                <PopOver key={index} style={{ display: popOver.isVisible ? 'block' : 'none' }}>
                    <TermPadding>
                        <BtnCloseModal onClick={() => togglePopOver(index)}>
                            <img src={arrowLeft} alt="뒤로" />
                        </BtnCloseModal>
                        <NavHeight />
                        <Fz18Bold $mb="2.3077rem">{popOver.title}</Fz18Bold>
                        <Paragraph $fz="1.154rem">
                            {popOver.content.split('\n').map((line, lineIndex) => (
                                <React.Fragment key={lineIndex}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </Paragraph>
                    </TermPadding>
                </PopOver>
            ))}
            {/* //약관 팝업 */}
        </>
    );
}
export default TermList;
