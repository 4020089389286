import { create } from 'zustand';

const initialState = {
    category: {
        shop: true,
        price: false,
        review: false,
    },
    order: {
        good: true,
        distance: false,
        rate: false,
    },
};

const useDesignerStore = create((set) => ({
    ...initialState,
    toggleCategory: (select) => {
        set((state) => ({
            category: {
                shop: select === 'shop',
                price: select === 'price',
                review: select === 'review',
            },
        }));
    },

    toggleOrder: (select) => {
        set((state) => ({
            order: {
                good: select === 'good',
                distance: select === 'distance',
                rate: select === 'rate',
            },
        }));
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useDesignerStore;
