import { create } from 'zustand';

const initialState = {
    fields: {
        name: '',
        phone: '',
    },

    modal: {
        address: false,
    },
};

const useDealerStore = create((set) => ({
    ...initialState,
    setField: (obj) => {
        set((state) => ({
            fields: {
                ...state.fields,
                ...obj,
            },
        }));
    },
    setOpeningHour: (obj) => {
        set((state) => ({
            opening_hour_set: {
                ...state.opening_hour_set,
                ...obj,
            },
        }));
    },
    setFieldOpeningHour: (obj) => {
        set((state) => {
            console.log('setFieldOpeningHour - state:', [
                ...state.fields.opening_hour,
                ...obj.opening_hour,
            ]);
            return {
                fields: {
                    ...state.fields,
                    opening_hour: [...state.fields.opening_hour, ...obj.opening_hour],
                },
            };
        });
    },
    setFile: (type, data) => {
        set((state) => {
            let updatedFile = { ...state.file };

            if (type === 'main') {
                updatedFile = {
                    ...updatedFile,
                    main: data,
                };
            } else if (type === 'sub') {
                updatedFile = {
                    ...updatedFile,
                    sub: [...updatedFile.sub, ...data],
                };
            }

            return {
                file: updatedFile,
            };
        });
    },
    setIsSubmit: (boolean) => {
        set({ isSubmit: boolean });
    },
    toggleModal: (select) => {
        set((state) => ({
            modal: {
                [select]: !state.modal[select],
            },
        }));
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useDealerStore;
