import React, { useEffect, useRef, useState } from 'react';
import { format, parse } from 'date-fns';

import {
    Fz16Medium,
    Container,
    ContainerPadding,
    BorderBoxMb,
    Divider,
    InputWrapper,
    ItemInfoCol2,
    InfoLabelR,
    TabAccent,
    Gap10,
    FlxMiddleMb30,
    BtnFullLineGreen,
    Fz18Bold,
    Mb30,
    Paragraph,
    LinkLogin,
    ColAccent2,
    Pad20,
    PopShortMsg,
    BtnFullModal,
    ToggleShow,
    Mb20,
    WarnInput,
    ConfirmInfoItem,
    InfoLabel,
    InfoValue,
    NumDateRange,
    BorderBox,
    FlxbtFullMb15,
    ColBlack,
    FlxbtFull,
    PadVert20,
    Fz15,
    Spacing30,
    CalcTable,
    BtnCloseModal3,
    CusModalTit,
    BtnInInputRound,
    LineCenter,
    Fz15Bold,
    TabWrapper,
    TabAccent2,
    ListColumn
} from '@/css/style';
import NewModal from '@/components/NewModal';
import RsvDayPicker from '@/components/DayPicker';
import icCalendar from '@/assets/img/ic_calendar_gray.svg';
import icCloseModal from '@/assets/img/ic_close_modal_gray.svg';
import {
    useGetSubscriptionQuery,
    useGetSubscriptionSummaryQuery,
    useGetCommissionQuery
} from '../../services/managerService';
import { useIntersectionObserver } from '../../utils/helper';
import useShowStore from '../../stores/common/show';
import useUserStore from '../../stores/user/user';
import arrowDownThinGray from '@/assets/img/arrow_down_thin_gray.svg';
import NoContent from '../../components/NoContent';
import useNumbersStore from '../../stores/user/number';

function Numbers() {
    const [range, setRange] = useState({ from: '', to: '' });
    const ss = useShowStore();
    const us = useUserStore();
    const ns = useNumbersStore();
    const { data: subscriptionSummaryData } = useGetSubscriptionSummaryQuery(
        {
            from_date: range.from ? format(range.from, 'yyyy-MM-dd') : undefined,
            to_date: range.to ? format(range.to, 'yyyy-MM-dd') : undefined,
        },
        us.user.id
    );
    console.log('subscriptionSummaryData', subscriptionSummaryData);
    const {
        data: subscriptionData,
        isLoading: subscriptionDataLoading,
        refetch: subscriptionDataRefetch,
        fetchNextPage: subscriptionDataFetchNextPage,
        hasNextPage: subscriptionDataHasNextPage,
        isFetchingNextPage: subscriptionDataIsFetchingNextPage,
    } = useGetSubscriptionQuery(
        {
            from_date: range.from ? format(range.from, 'yyyy-MM-dd') : undefined,
            to_date: range.to ? format(range.to, 'yyyy-MM-dd') : undefined,
            status: ns.status,
        },
        us.user.id
    );

    const {
        data: commissionData,
        isLoading: commissionDataLoading,
        refetch: commissionDataRefetch,
        fetchNextPage: commissionDataFetchNextPage,
        hasNextPage: commissionDataHasNextPage,
        isFetchingNextPage: commissionDataIsFetchingNextPage,
    } = useGetCommissionQuery(
        {
            
        },
        us.user.id
    );

    console.log(range);
    const subscriptionRef = useRef(null);
    const observerSubscription = useIntersectionObserver(
        subscriptionDataFetchNextPage,
        subscriptionRef,
        subscriptionData,
        subscriptionDataIsFetchingNextPage
    );

    const commissionRef = useRef(null);
    const observerCommission = useIntersectionObserver(
        commissionDataFetchNextPage,
        commissionRef,
        commissionData,
        commissionDataIsFetchingNextPage
    );
    useEffect(() => {
        return () => {
            if (observerSubscription.current) {
                observerSubscription.current.disconnect();
            }

            if (observerCommission.current) {
                observerCommission.current.disconnect();
            }
        };
    }, [observerSubscription, observerCommission]);
    useEffect(() => {
        subscriptionDataLoading || commissionDataLoading ? ss.setLottie(true) : ss.setLottie(false);
    }, [subscriptionDataLoading, commissionDataLoading]);

    console.log('subscriptionData', subscriptionData);
    console.log('commissionData', commissionData);
    return (
        <>
            <Container className="main-container">
                <ContainerPadding>
                    <FlxMiddleMb30>
                        <TabAccent
                            $active={ns.screen.calculate}
                            onClick={() => ns.setScreen('calculate')}
                        >
                            정산조회
                        </TabAccent>
                        <Gap10 />
                        <TabAccent
                            $active={ns.screen.history}
                            onClick={() => ns.setScreen('history')}
                        >
                            정산 내역 조회
                        </TabAccent>
                    </FlxMiddleMb30>
                    <ToggleShow $visible={ns.screen.calculate}>
                        <FlxbtFull>
                            <Fz18Bold>정산 내역</Fz18Bold>
                            <button type="button" onClick={() => ns.toggleModal('period')}>
                                <img src={icCalendar} alt="" />
                            </button>
                        </FlxbtFull>
                        <PadVert20>
                            <Fz16Medium as={BorderBox} $pd="1.1538rem 1.923rem">
                                <FlxbtFullMb15>
                                    총 결제 금액
                                    <ColBlack>
                                        {subscriptionSummaryData?.data.price.toLocaleString()}원
                                    </ColBlack>
                                </FlxbtFullMb15>
                                <FlxbtFull>
                                    지급 정산 금액
                                    <ColBlack>
                                        {' '}
                                        {subscriptionSummaryData?.data.commission.toLocaleString()}
                                        원
                                    </ColBlack>
                                </FlxbtFull>
                            </Fz16Medium>
                        </PadVert20>
                        <NumDateRange>
                            <InputWrapper>
                                <input
                                    type="text"
                                    name="calc_start"
                                    id="calc_start"
                                    value={range.from ? format(range.from, 'yyyy-MM-dd') : ''}
                                    onClick={() => ns.toggleModal('period')}
                                    readOnly
                                />
                            </InputWrapper>
                            <span>~</span>
                            <InputWrapper>
                                <input
                                    type="text"
                                    name="calc_end"
                                    id="calc_end"
                                    value={range.to ? format(range.to, 'yyyy-MM-dd') : ''}
                                    onClick={() => ns.toggleModal('period')}
                                    readOnly
                                />
                            </InputWrapper>
                            <BtnInInputRound>검색</BtnInInputRound>
                        </NumDateRange>
                        <TabWrapper $mb="2.3077rem">
                            <TabAccent2 $active={ns.status === ''} onClick={() => ns.setStatus('')}>
                                전체
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={ns.status === 'pending'}
                                onClick={() => ns.setStatus('pending')}
                            >
                                정산대기
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={ns.status === 'confirmed'}
                                onClick={() => ns.setStatus('confirmed')}
                            >
                                정산완료
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={ns.status === 'canceled'}
                                onClick={() => ns.setStatus('canceled')}
                            >
                                결제취소
                            </TabAccent2>
                        </TabWrapper>
                        <Fz15 as={Mb20}>디폴트 정산 기간은 1개월 ( 정산주기 확인 )</Fz15>
                        {!subscriptionDataLoading &&
                            !(
                                subscriptionData?.pages &&
                                subscriptionData?.pages[0].data.docs.length > 0
                            ) && <NoContent message="검색된 정산내역이 없습니다." />}
                        {subscriptionData?.pages.map((page, pageIndex) =>
                            page.data.docs.map((items, index) => (
                                <>
                                    <CalcTable key={items.id}>
                                        <thead>
                                            <tr>
                                                <th rowSpan="2">NO</th>
                                                <th colSpan="5">디자이너</th>
                                            </tr>
                                            <tr>
                                                <th>구분</th>
                                                <th>결제금</th>
                                                <th>정산금</th>
                                                <th>결제수단</th>
                                                <th>상태</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td rowSpan="2">
                                                    {page.data.totalDocs - index - pageIndex * 10}
                                                </td>
                                                <td colSpan="5">{items.designer.title}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {items.product.type === 'free'
                                                        ? '무료'
                                                        : '유로'}
                                                </td>
                                                <td>
                                                    {items.product.type !== 'free'
                                                        ? items.price.toLocaleString()
                                                        : '-'}
                                                </td>
                                                <td>
                                                    {items.commission_percent > 0 && (
                                                        <>
                                                            {`${items.commission_percent}%`}
                                                            <br />
                                                        </>
                                                    )}
                                                    {items.product.type !== 'free'
                                                        ? items.commission.toLocaleString()
                                                        : '-'}
                                                </td>
                                                <td>
                                                    {items.product.type !== 'free'
                                                        ? items.payment?.method === 'mobile'
                                                            ? '휴대폰결제'
                                                            : '카드결제'
                                                        : '-'}
                                                </td>
                                                <td>
                                                    {items.product.type !== 'free'
                                                        ? (() => {
                                                              switch (items.status) {
                                                                  case 'pending':
                                                                      return '정산대기';
                                                                  case 'confirmed':
                                                                      return '정산완료';
                                                                  case 'cancelled':
                                                                      return '결제취소';
                                                                  default:
                                                                      return '';
                                                              }
                                                          })()
                                                        : '-'}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </CalcTable>
                                    <Mb20 />
                                </>
                            ))
                        )}
                        {subscriptionDataHasNextPage && (
                            <LineCenter ref={subscriptionRef}>
                                <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                                <img src={arrowDownThinGray} alt="more" />
                            </LineCenter>
                        )}
                    </ToggleShow>
                    <ToggleShow $visible={ns.screen.history}>
                        <ListColumn>
                            {!commissionDataLoading &&
                                !(
                                    commissionData?.pages &&
                                    commissionData?.pages[0].data.docs.length > 0
                                ) && <NoContent message="정산 받으실 내역이 없습니다." />}
                            {commissionData?.pages.map((page, pageIndex) =>
                                page.data.docs.map((items, index) => (
                                    <>
                                        <BorderBoxMb key={items.id}>
                                            <Fz16Medium>No. {page.data.totalDocs - index - pageIndex * 10}</Fz16Medium>
                                            <Divider $mt="1.3846rem" $mb="1.154rem" />
                                            <Fz15 as="div">
                                                <ItemInfoCol2 $mb="1.154rem">
                                                    <ColBlack as={InfoLabelR}>정산기간</ColBlack>
                                                    <InfoValue>
                                                        {items.settlementPeriod}
                                                    </InfoValue>
                                                </ItemInfoCol2>
                                                <ItemInfoCol2 $mb="1.154rem">
                                                    <ColBlack as={InfoLabelR}>정산 지급일</ColBlack>
                                                    <InfoValue>
                                                        {items.settlementDate}
                                                    </InfoValue>
                                                </ItemInfoCol2>
                                                <ItemInfoCol2>
                                                    <ColBlack as={InfoLabelR}>정산 금액</ColBlack>
                                                    <InfoValue>
                                                        <strong>{items.commission.toLocaleString()}원</strong>
                                                    </InfoValue>
                                                </ItemInfoCol2>
                                            </Fz15>
                                        </BorderBoxMb>
                                    </>
                                ))
                            )}
                            {commissionDataHasNextPage && (
                                <LineCenter ref={commissionRef}>
                                    <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                                    <img src={arrowDownThinGray} alt="more" />
                                </LineCenter>
                            )}
                        </ListColumn>
                                
                        {/* <BorderBoxMb>
                            <Fz16Medium>No. 1</Fz16Medium>
                            <Divider $mt="1.3846rem" $mb="1.154rem" />
                            <Fz15 as="div">
                                <ItemInfoCol2 $mb="1.154rem">
                                    <ColBlack as={InfoLabelR}>정산기간</ColBlack>
                                    <InfoValue>2024.02.01 ~ 24.02.29</InfoValue>
                                </ItemInfoCol2>
                                <ItemInfoCol2 $mb="1.154rem">
                                    <ColBlack as={InfoLabelR}>정산 지급일</ColBlack>
                                    <InfoValue>2024.03.01</InfoValue>
                                </ItemInfoCol2>
                                <ItemInfoCol2>
                                    <ColBlack as={InfoLabelR}>정산 금액</ColBlack>
                                    <InfoValue>
                                        <strong>2,154,000원</strong>
                                    </InfoValue>
                                </ItemInfoCol2>
                            </Fz15>
                        </BorderBoxMb> */}
                    </ToggleShow>
                </ContainerPadding>
            </Container>
            {/* 기간 설정 모달 */}
            <NewModal contentLabel="기간 설정" ariaHideApp={false} isOpen={ns.modal.period}>
                <BtnCloseModal3>
                    <img src={icCloseModal} alt="닫기" onClick={() => ns.toggleModal('period')} />
                </BtnCloseModal3>
                <Pad20>
                    <Fz15 as="form">
                        <PopShortMsg>
                            <CusModalTit>기간 설정</CusModalTit>
                            <Spacing30 />
                            <RsvDayPicker
                                mode="range"
                                disabled={false}
                                selected={range}
                                onSelect={setRange}
                                classNames="syhi"
                            />
                        </PopShortMsg>
                        <BtnFullModal
                            type="button"
                            $active={range.from || range.to}
                            onClick={() => {
                                if (range.from || range.to) {
                                    ns.toggleModal('period');
                                    subscriptionDataRefetch();
                                }
                            }}
                        >
                            저장
                        </BtnFullModal>
                    </Fz15>
                </Pad20>
            </NewModal>
            {/* //기간 설정 모달 */}
        </>
    );
}

export default Numbers;
