import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@/css/slick_custom.css';
import {
    Block,
    LiStyled,
    CircleImg,
    Fz18Medium,
    Container,
    ItemDesigner,
    Mb20,
    DesignerInfo,
    FullImg,
    Mb30,
    FlxMiddle,
    Gap10,
    ContainerPadding,
    Fz20Bold,
    ColAccent,
    FlxbtFullMb20,
    DesignerRates2,
    Divider,
    ModalHeader,
    BtnCloseModal2,
    ModalPhoto,
    BtnModalPhotoLeft,
    BtnModalPhotoRight,
    FlxbtFullMb10,
    Pad20,
    BtnFullModal,
    Textbox,
    ListCol3,
    MembStatusPurple,
    BtnChgStatusGreen,
    BtnChgStatusPurple,
    BtnModalPhoto,
    MembStatusGreen,
    Select
} from '@/css/style';
import useDesignerStore from '@/stores/designer/designer';
import {
    useDesignerDetailQuery,
    useUpdateDesignerDealerMutation
} from '@/services/managerService';
import config from '@/config/index';
import useUserStore from '@/stores/user/user';
import altimg from '@/assets/img/altimg.png';
import arrowLeftWhite from '@/assets/img/arrow_left_wh.svg';
import arrowRightWhite from '@/assets/img/arrow_right_wh.svg';
import icCloseModal from '@/assets/img/ic_close_modal_gray.svg';
import icReview from '@/assets/img/ic_review.svg';
import snsFb from '@/assets/img/sns_fb.svg';
import snsIg from '@/assets/img/sns_ig.svg';
import snsX from '@/assets/img/sns_x.svg';
import snsYt from '@/assets/img/sns_ytube.svg';
import { FaStar } from 'react-icons/fa';
import NewModal from '@/components/NewModal';
import {
    useActiveDesignerMutation,
    useInactiveDesignerMutation,
    useApproveDesignerMutation,
    useDisapproveDesignerMutation,
} from '../../services/managerService';
import useShowStore from '../../stores/common/show';
import { useGetDealersQuery } from '../../services/dealerService';

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <BtnModalPhoto className={className} style={{ ...style }} onClick={onClick}>
            <img src={arrowRightWhite} alt="" />
        </BtnModalPhoto>
    );
}
function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <BtnModalPhoto className={className} style={{ ...style }} onClick={onClick}>
            <img src={arrowLeftWhite} alt="" />
        </BtnModalPhoto>
    );
}
function DesignerDetail() {
    const ds = useDesignerStore();
    const us = useUserStore();
    const ss = useShowStore();
    const params = useParams();
    const navigate = useNavigate();
    
    const {
        data: designerData,
        refetch: designerDataRefetch,
        isLoading,
    } = useDesignerDetailQuery(params.id, us.user.id);

    const ActiveDesignerMutation = useActiveDesignerMutation(designerDataRefetch);
    const InactiveDesignerMutation = useInactiveDesignerMutation(designerDataRefetch);
    const ApproveDesignerMutation = useApproveDesignerMutation(designerDataRefetch);
    const DisapproveDesignerMutation = useDisapproveDesignerMutation(designerDataRefetch);
    const UpdateDesignerDealerMutation = useUpdateDesignerDealerMutation(designerDataRefetch);

    const dealerData = useGetDealersQuery({
        manager: us.user.id,
        page: 1,
        pageSize: 100,
    });

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [dealerId, setDealerId] = React.useState('');
    const [currentImageList, setCurrentImageList] = React.useState([]);
    const [currentImagePath, setCurrentImagePath] = React.useState(null);
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0); // 상태 추가
    function openModal(index, imgList, path) {
        setCurrentImageIndex(index);
        setCurrentImagePath(path);
        setCurrentImageList(imgList);
        setIsOpen(true); // 모달을 열어 해당 이미지를 보여줍니다.
    }
    function closeModal() {
        setIsOpen(false);
    }
    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => {
            const newIndex = prevIndex + 1 >= currentImageList.length ? 0 : prevIndex + 1;
            return newIndex;
        });
    };
    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => {
            const newIndex = prevIndex - 1 < 0 ? currentImageList.length - 1 : prevIndex - 1;
            return newIndex;
        });
    };

    useEffect(() => {
        isLoading ? ss.setLottie(true) : ss.setLottie(false);
    }, [isLoading]);

    useEffect(() => {
        if (designerData?.data) {
            if(designerData.data?.dealer){
                console.log('designerData.data?.dealer', designerData.data?.dealer);
                setDealerId(designerData.data.dealer.id);
            }
        }
    }, [designerData]);

    if (!isLoading) {
        return (
            <>
                <Container className="main-container">
                    <Mb30>
                        <ContainerPadding>
                            {/* 디자이너 프로필 영역 */}
                            <FlxbtFullMb20>
                                <DesignerRates2>
                                    <img src={icReview} alt="후기" />{' '}
                                    <strong>{designerData?.data.ratingCount}</strong>
                                    <FaStar color="#FFBB00" />{' '}
                                    <strong>{designerData?.data.averageRating.toFixed(1)}</strong>
                                </DesignerRates2>
                                {/* {designerData?.data.approved ? (
                                    <MembStatusGreen>승인</MembStatusGreen>
                                ) : (
                                    <MembStatusPurple>심사</MembStatusPurple>
                                )} */}
                                <div>
                                        {designerData?.data.subscription ? (<>
                                        <MembStatusGreen>라이센스</MembStatusGreen>
                                        {
                                            designerData?.data.actived ? 
                                            <MembStatusGreen>판매</MembStatusGreen>
                                            : 
                                            <MembStatusPurple>숨김</MembStatusPurple>    
                                        }
                                        </>
                                        ) : designerData?.data.approved ? (
                                            <MembStatusGreen>승인</MembStatusGreen>
                                        ) : !designerData?.data.approved ? (
                                            <MembStatusPurple>심사</MembStatusPurple>
                                        ) : null}
                                </div>
                            </FlxbtFullMb20>
                            <ItemDesigner $mb="1.538rem">
                                <CircleImg style={circleProfile}>
                                    <FullImg
                                        src={`${config.DESIGNER_IMG_URL}${designerData?.data.profileImage?.filename}?size=110`}
                                        onError={(e) => {
                                            e.currentTarget.src = altimg;
                                        }}
                                        alt="프로필 이미지"
                                    />
                                </CircleImg>
                                <DesignerInfo>
                                    <FlxbtFullMb10>
                                        <ColAccent>{designerData?.data.shop.name}</ColAccent>
                                        <FlxMiddle>
                                            {designerData?.data.sns.map((items, index) => {
                                                const provider = snsOptions
                                                    .filter(
                                                        (option) =>
                                                            items.provider === option.provider
                                                    )
                                                    .map((option) => option.content);
                                                return (
                                                    <React.Fragment key={items.id}>
                                                        <a
                                                            href={items.link}
                                                            target="_blank"
                                                            rel="noreferrer noopener"
                                                        >
                                                            <img
                                                                src={provider}
                                                                alt={provider}
                                                                style={{
                                                                    width: '24px',
                                                                    height: '24px',
                                                                }}
                                                            />
                                                        </a>
                                                        {index <
                                                            designerData?.data.sns.length - 1 && (
                                                            <Gap10 />
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </FlxMiddle>
                                    </FlxbtFullMb10>
                                    <Mb20>
                                        <Fz20Bold>{designerData?.data.title}</Fz20Bold>
                                    </Mb20>
                                    {/* <strong>누적예약: n회</strong>
                                <Spacing10 />
                                <strong>대기예약: n회</strong> */}
                                </DesignerInfo>
                            </ItemDesigner>
                            {/* //디자이너 프로필 영역 */}
                            <Divider $mb="2.3077rem" />
                            <Fz18Medium $mb="1.5378rem">심사/승인 상태</Fz18Medium>
                            <ListCol3 as={Mb30}>
                                {!designerData?.data.approved ? <BtnChgStatusGreen
                                    onClick={() => ApproveDesignerMutation.mutate(params.id)}
                                >
                                    승인으로 변경
                                </BtnChgStatusGreen>
                                :
                                <BtnChgStatusPurple
                                    onClick={() => DisapproveDesignerMutation.mutate(params.id)}
                                >
                                    심사로 변경
                                </BtnChgStatusPurple>}
                            </ListCol3>
                            {designerData?.data.subscription && <>
                            <Fz18Medium $mb="1.5378rem">판매/숨김 상태</Fz18Medium>
                            <ListCol3 as={Mb30}>
                                {!designerData?.data.actived ? <BtnChgStatusGreen
                                    onClick={() => {
                                        if(designerData?.data.menus.length === 0){
                                            alert('메뉴가 등록되어 있지 않습니다.');
                                            return;
                                        }

                                        ActiveDesignerMutation.mutate({
                                            managerId: us.user.id,
                                            designerId: params.id
                                        })
                                    }}
                                >
                                    판매로 변경
                                </BtnChgStatusGreen>
                                :
                                <BtnChgStatusPurple
                                    onClick={() => InactiveDesignerMutation.mutate({
                                        managerId: us.user.id,
                                        designerId: params.id
                                    })}
                                >
                                    숨김으로 변경
                                </BtnChgStatusPurple>}
                            </ListCol3>
                            </>}
                            <Fz18Medium $mb="1.538rem">딜러</Fz18Medium>
                            <Select
                                // ref={setRef(item.day)}
                                onChange={(e) => {
                                    setDealerId(e.target.value);
                                    UpdateDesignerDealerMutation.mutate({
                                        managerId: us.user.id,
                                        designerId: params.id,
                                        dealer_id: e.target.value,
                                    });
                                }}
                                defaultValue={dealerId}
                                style={{color: '#000', fontSize: '16px'}}   
                            >
                                <option value="">미지정</option>
                                {dealerData && dealerData?.data?.data && dealerData?.data?.data.docs.map((dealer) => (
                                    <option key={dealer._id} value={dealer._id} selected={dealer._id === dealerId}>
                                        {dealer.name} / {dealer.phone} / {dealer.referer_code}
                                    </option>
                                ))}
                            </Select>
                            {designerData?.data.career && (
                                <Block>
                                    <Fz18Medium $mb="1.538rem">디자이너 프로필</Fz18Medium>
                                    <ul>
                                        <LiStyled>{designerData?.data.career}</LiStyled>
                                        {/* <LiStyled>
                                    <span>•</span>
                                    <p>TIGI MODERN CLASSIC CUT DIPLOMA 수료</p>
                                </LiStyled>
                                <LiStyled>
                                    <span>•</span>
                                    <p>LeeChoi academy designer DIPLOMA 수료</p>
                                </LiStyled>
                                <LiStyled>
                                    <span>•</span>
                                    <p>2021년 ~ 2022년 CLUB LOREAL 공식 파트너쉽 살롱</p>
                                </LiStyled> */}
                                    </ul>
                                </Block>
                            )}
                            {designerData?.data.introduce && (
                                <Block>
                                    <Fz18Medium $mb="1.538rem">디자이너 소개</Fz18Medium>
                                    <ul>
                                        <LiStyled>{designerData?.data.introduce}</LiStyled>
                                    </ul>
                                </Block>
                            )}
                            {/* 디자이너 할인 안내 */}
                            {/* 메모: 등록된 배너가 있을때만 노출되는 영역입니다 */}
                            {/* <Fz18Medium $mb="1.538rem">디자이너 할인 안내</Fz18Medium>
                        <DetailDcBanner>
                            <FullImg src="//shorturl.at/fjklN" alt="배너" />
                        </DetailDcBanner> */}
                            {/* //디자이너 할인 안내 */}
                        </ContainerPadding>
                    </Mb30>
                </Container>
                <Modal ariaHideApp={false} style={slideShowStyles}>
                    <ModalHeader>
                        <BtnCloseModal2>
                            <img src={icCloseModal} alt="닫기" />
                        </BtnCloseModal2>
                        <Fz18Medium>상세보기(/)</Fz18Medium>
                    </ModalHeader>
                    <ModalPhoto>
                        <BtnModalPhotoLeft>
                            <img src={arrowLeftWhite} />
                        </BtnModalPhotoLeft>
                        <FullImg src="" />
                        <BtnModalPhotoRight>
                            <img src={arrowRightWhite} />
                        </BtnModalPhotoRight>
                    </ModalPhoto>
                </Modal>
                <Modal isOpen={modalIsOpen} ariaHideApp={false} style={slideShowStyles}>
                    <ModalHeader>
                        <BtnCloseModal2 onClick={closeModal}>
                            <img src={icCloseModal} alt="닫기" />
                        </BtnCloseModal2>
                        <Fz18Medium>
                            상세보기({currentImageIndex + 1}/{currentImageList.length})
                        </Fz18Medium>
                    </ModalHeader>
                    <ModalPhoto>
                        <BtnModalPhotoLeft onClick={() => prevImage()}>
                            <img src={arrowLeftWhite} />
                        </BtnModalPhotoLeft>
                        <FullImg
                            src={
                                config.API_SERVER_HOST +
                                currentImagePath +
                                currentImageList[currentImageIndex]?.filename
                            }
                            onError={(e) => {
                                e.currentTarget.src = altimg;
                            }}
                        />
                        <BtnModalPhotoRight onClick={() => nextImage()}>
                            <img src={arrowRightWhite} />
                        </BtnModalPhotoRight>
                    </ModalPhoto>
                </Modal>
                {/* 거부 사유 모달 */}
                <NewModal>
                    <Pad20>
                        <Mb20>
                            {/* 메모: texbox클릭하면 $active 되도록 */}
                            <Textbox placeholder="거부 사유를 입력해 주세요" rows="10"></Textbox>
                        </Mb20>
                        <BtnFullModal $active>메시지 전달</BtnFullModal>
                    </Pad20>
                </NewModal>
                {/* //거부 사유 모달 */}
            </>
        );
    }
}
const sliderStyle = {
    autoplay: true,
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
};
const slideShowStyles = {
    overlay: {
        backgroundColor: 'unset',
        zIndex: 102,
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        overflowY: 'auto',
        top: 0,
        right: 'unset',
        bottom: 0,
        left: '50%',
        width: '100%',
        maxWidth: 440,
        padding: 0,
        transform: 'translateX(-50%)',
        border: 0,
        zIndex: 103,
        backgroundColor: 'rgba(0,0,0,.85)',
    },
};
const circleProfile = {
    border: '3px solid #00BF9D',
};
const hourLabelStyle = {
    width: '14%',
};
const snsOptions = [
    { id: 0, provider: 'Instagram', content: snsIg },
    { id: 1, provider: 'Twitter', content: snsX },
    { id: 2, provider: 'Facebook', content: snsFb },
    { id: 3, provider: 'Youtube', content: snsYt },
];

export default DesignerDetail;
