import { instance } from '@services/api';
import { useQuery, useMutation, useInfiniteQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const shopService = {
    shop: (page, params) => {
        return instance.get('/shops', {
            params: {
                page: page,
                ...params,
            },
        });
    },
    shopDetail: (id) => {
        return instance.get(`/shops/${id}`);
    },
    shopCreate: (data) => {
        return instance.post('/shops', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    shopUpdate: (id, data) => {
        return instance.put(`/shops/${id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    shopDelete: (id) => {
        return instance.delete(`/shops/${id}`);
    },
};

export const useShopQuery = (params) => {
    return useInfiniteQuery({
        queryKey: ['shop', params],
        queryFn: ({ pageParam }) => shopService.shop(pageParam, params),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.data.nextPage;
        },
        enabled: false,
        gcTime: 0,
    });
};

export const useShopDetailQuery = (id) => {
    return useQuery({
        queryKey: ['shopDetail', id],
        queryFn: () => shopService.shopDetail(id),
    });
};

export const useShopCreateMutation = () => {
    const navigate = useNavigate();
    return useMutation({
        mutationFn: async (obj) => {
            const formData = new FormData();
            for (const key in obj) {
                if (Object.hasOwnProperty.call(obj, key)) {
                    if (Array.isArray(obj[key])) {
                        if (key === 'opening_hour') {
                            obj[key].forEach((item, index) => {
                                for (const subKey in item) {
                                    if (Object.hasOwnProperty.call(item, subKey)) {
                                        formData.append(
                                            `${key}[${index}][${subKey}]`,
                                            item[subKey]
                                        );
                                    }
                                }
                            });
                        } else if (key === 'files') {
                            obj[key].forEach((item) => {
                                formData.append('files', item);
                            });
                        }
                    } else {
                        formData.append(key, obj[key]);
                    }
                }
            }

            const response = await shopService.shopCreate(formData);
            return response;
        },
        onSuccess: (res) => {
            toast.success('매장이 추가되었습니다.');
            navigate('/', { replace: true });
        },
        onError: (error) => {
            toast.error(error.message);
        },
    });
};

export const useShopUpdateMutation = (id) => {
    const navigate = useNavigate();
    return useMutation({
        mutationFn: async (obj) => {
            const formData = new FormData();
            for (const key in obj) {
                if (Object.hasOwnProperty.call(obj, key)) {
                    if (Array.isArray(obj[key])) {
                        if (key === 'opening_hour') {
                            obj[key].forEach((item, index) => {
                                for (const subKey in item) {
                                    if (Object.hasOwnProperty.call(item, subKey)) {
                                        formData.append(
                                            `${key}[${index}][${subKey}]`,
                                            item[subKey]
                                        );
                                    }
                                }
                            });
                        } else if (key === 'files') {
                            obj[key].forEach((item) => {
                                formData.append('files', item);
                            });
                        } else {
                            obj[key].forEach((item, index) => {
                                formData.append(`${key}[${index}]`, item);
                            });
                        }
                    } else {
                        formData.append(key, obj[key]);
                    }
                }
            }

            const response = await shopService.shopUpdate(id, formData);
            return response;
        },
        onSuccess: (res) => {
            toast.success('매장이 수정되었습니다.');
            navigate('/', { replace: true });
        },
        onError: (error) => {
            toast.error(error.message);
        },
    });
};

export const useShopDeleteMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (id) => {
            const response = await shopService.shopDelete(id);
            return response;
        },
        onSuccess: (res) => {
            toast.success('매장이 삭제되었습니다.');
            onSuccess(res);
        },
        onError: (error) => {
            toast.error(error.message);
        },
    });
};
