import React, { useEffect, useRef, useState } from 'react';
import {
    ColAccent2,
    Container,
    ContainerPadding,
    Fz20Bold,
    NavHeight,
    Mb30,
    PopOver,
    StickyBtm,
    BtnFullModal,
    Fz18Bold,
    Spacing70,
    Fz15Bold,
    JoinField,
    PadVert10,
    InputWrapper,
    JoinInput,
    BtnClearInput,
    WarnInput,
    DescInput,
    BtnCloseModal,
    BtnInInputRound,
    BtnLineGreenShorter,
    TimeLeft,
    Mb10,
    Paragraph,
    PadVert30,
    Pad20,
    PopShortMsg,
    Mb20,
    ListColumn,
    FlxbtFullMb20,
    Fz18Medium,
    Fz15,
    BtnFullLineGreen,
    LineCenter,
    AbsolBtm,
    JoinInputPlc15,
} from '@/css/style';
import useMyPageStore from '@/stores/user/mypage';
import { useGetManagerQuery, useUpdateManagerMutation } from '../../services/managerService';
import useUserStore from '../../stores/user/user';
import useShowStore from '@/stores/common/show';
import { toast } from 'react-toastify';

function Mypage() {
    const ms = useMyPageStore();
    const us = useUserStore();
    const ss = useShowStore();
    const { data: managerData, isLoading } = useGetManagerQuery(us.user.id);
    const UpdateManagerMutation = useUpdateManagerMutation();
    console.log('data', managerData?.data);
    useEffect(() => {
        return () => {
            ms.terminate();
        };
    }, []);

    const handleSubmit = () => {
        if (ms.fields.name === '') {
            toast.error('이름을 입력해주세요.');
            return;
        }
        if (ms.fields.password !== ms.fields.confirm_password) {
            toast.error('비밀번호가 일치하지 않습니다.');
            return;
        }
        UpdateManagerMutation.mutate(ms.fields);
    };

    useEffect(() => {
        isLoading ? ss.setLottie(true) : ss.setLottie(false);
        ms.setField({ name: managerData?.data.name, manager: managerData?.data._id });
    }, [isLoading]);

    if (!isLoading) {
        return (
            <>
                <Container className="main-container">
                    <ContainerPadding>
                        <Fz20Bold $mb="3rem">내 정보</Fz20Bold>
                        {/* 정보 목록 */}
                        <JoinField $mb="3.846rem">
                            <Mb10>
                                <Fz15Bold>정산 퍼센트</Fz15Bold>
                            </Mb10>
                            <InputWrapper>
                                {`${managerData?.data.region.commission_rate}%`}
                            </InputWrapper>
                        </JoinField>
                        <JoinField $mb="3.846rem">
                            <Mb10>
                                <Fz15Bold>이메일/아이디</Fz15Bold>
                            </Mb10>
                            <InputWrapper>{managerData?.data.user_id}</InputWrapper>
                        </JoinField>
                        <JoinField $mb="3.846rem">
                            <Mb10>
                                <Fz15Bold>휴대폰 번호</Fz15Bold>
                            </Mb10>
                            <InputWrapper>{managerData?.data.phone}</InputWrapper>
                        </JoinField>
                        <JoinField $mb="3.846rem">
                            <Mb10>
                                <Fz15Bold>성명</Fz15Bold>
                            </Mb10>
                            <InputWrapper>
                                <JoinInputPlc15
                                    type="text"
                                    value={ms.fields.name}
                                    onChange={(e) => ms.setField({ name: e.target.value })}
                                />
                            </InputWrapper>
                        </JoinField>
                        <JoinField $mb="3.846rem">
                            <Mb10>
                                <Fz15Bold>비밀번호</Fz15Bold>
                            </Mb10>
                            <InputWrapper>
                                <JoinInputPlc15
                                    type="password"
                                    value={ms.fields.password}
                                    onChange={(e) => ms.setField({ password: e.target.value })}
                                />
                            </InputWrapper>
                        </JoinField>
                        <JoinField $mb="3.846rem">
                            <Mb10>
                                <Fz15Bold>비밀번호 확인</Fz15Bold>
                            </Mb10>
                            <InputWrapper>
                                <JoinInputPlc15
                                    type="password"
                                    value={ms.fields.confirm_password}
                                    onChange={(e) =>
                                        ms.setField({ confirm_password: e.target.value })
                                    }
                                />
                            </InputWrapper>
                        </JoinField>
                    </ContainerPadding>
                    <StickyBtm>
                        <BtnFullModal $active onClick={handleSubmit}>
                            수정
                        </BtnFullModal>
                    </StickyBtm>
                </Container>
            </>
        );
    }
}
export default Mypage;
