import { create } from 'zustand';

const initialState = {
    isActive: false,
};

const useAsideStore = create((set) => ({
    ...initialState,
    toggleActive: () => {
        set((state) => ({
            isActive: !state.isActive,
        }));
    },
    setActive: (text) => {
        set((state) => ({
            isActive: text,
        }));
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useAsideStore;
