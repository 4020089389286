import { Link, useLocation } from 'react-router-dom';
import {
    Divider,
    NavList,
    LinkFlxbtFull,
    BtnCloseModal3,
    NavHeight,
    ColGray1,
    AbsolBtm,
    Gap30,
} from '@/css/style';
import { useQueryClient } from '@tanstack/react-query';
import icCloseModalBl from '@/assets/img/ic_close_modal_bl.svg';
import useAsideStore from '@/stores/common/aside';
import useShowStore from '@/stores/common/show';
import useUserStore from '@/stores/user/user';
import arrowRightGray from '@/assets/img/arrow_right_thin_gray.svg';

export default function Aside() {
    const ss = useShowStore();
    const us = useUserStore();
    const as = useAsideStore();
    const location = useLocation();
    const queryClient = useQueryClient();

    const handleCloseModal = () => {
        document.querySelector('.main-container').classList.remove('overlay');

        ss.setShowAside(false);
    };

    const asideStyle = {
        position: 'absolute',
        top: 0,
        right: ss.showAside ? '0' : '-100%',
        width: '80%',
        maxWidth: 350,
        height: '100vh',
        backgroundColor: '#fff',
        fontSize: '1.154rem',
        transition: 'right 0.3s ease-in-out',
        zIndex: 111,
    };

    return (
        <>
            <nav style={asideStyle}>
                <NavHeight />
                <BtnCloseModal3 $top="0.846rem" $rt="1.8077rem" onClick={handleCloseModal}>
                    <img src={icCloseModalBl} alt="닫기" />
                </BtnCloseModal3>
                <Divider $mb="1.538rem" />
                <NavList>
                    <LinkFlxbtFull to="/">
                        관리
                        <img src={arrowRightGray} alt="메뉴 바로가기" />
                    </LinkFlxbtFull>
                    <LinkFlxbtFull to="/numbers">
                        정산 내역
                        <img src={arrowRightGray} alt="메뉴 바로가기" />
                    </LinkFlxbtFull>
                    <LinkFlxbtFull to="/my">
                        내 정보 수정
                        <img src={arrowRightGray} alt="메뉴 바로가기" />
                    </LinkFlxbtFull>
                </NavList>
                <AbsolBtm style={{ left: '2.3077rem' }}>
                    <ColGray1
                        as="strong"
                        onClick={() => {
                            queryClient.clear();
                            us.logout();
                        }}
                    >
                        로그아웃
                    </ColGray1>
                </AbsolBtm>
            </nav>
        </>
    );
}
