import { create } from 'zustand';

const initialState = {
    redirect: null,
};

const useRedirectStore = create((set) => ({
    ...initialState,
    setRedirect: (txt) => {
        set({ redirect: txt });
    },
}));

export default useRedirectStore;
