import { jwtDecode } from 'jwt-decode';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialState = {
    accessToken: '',
    user: {},
};

const useUserStore = create(
    persist(
        (set) => ({
            ...initialState,
            setRole: (role, isWait) => {
                if (role === 'designer') {
                    set({ role: 'designer', isWait: isWait });
                } else {
                    set({ role: 'user', isWait: null });
                }
            },
            setIsSocial: (socialName) => {
                switch (socialName) {
                    case 'naver':
                        set({ isSocial: 'naver' });
                        break;
                    case 'kakao':
                        set({ isSocial: 'kakao' });
                        break;
                    case 'google':
                        set({ isSocial: 'google' });
                        break;
                    default:
                        set({ isSocial: 'email' });
                }
            },

            registUser: (obj) => {
                localStorage.setItem('accessToken', obj.accessToken);
                const accessToken = localStorage.getItem('accessToken');

                const decodedJwt = jwtDecode(accessToken);

                set((state) => ({
                    accessToken: obj.accessToken,
                    user: decodedJwt,
                }));
            },
            logout: () => {
                localStorage.clear();
                set(initialState);
                window.location.href = '/login';
            },
        }),
        { name: 'userStore' }
    )
);

export default useUserStore;
