import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Lottie } from '@crello/react-lottie';
import '@fontsource-variable/noto-sans-kr';
import { createGlobalStyle } from 'styled-components';
import { Frame, Main } from '@/css/style';
import useShowStore from '@/stores/common/show';
import Aside from '@/components/Aside';
import TopNavBar from '@/components/TopNavBar';
import animationData from '@/assets/lottieLoader.json';

export default function Layout() {
    const script = document.createElement('script');
    script.src =
        'https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=t2kntuhk0e&submodules=geocoder';
    script.async = true;
    useEffect(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.head.appendChild(script);
    }, []);

    const navigate = useNavigate();
    const location = useLocation();
    const ss = useShowStore();
    const { id } = useParams();

    useEffect(() => {
        const isLogoPath = ['/'];
        const isNotRightMenuPath = [
            '/archive',
            `/archive/${id}`,
            '/around',
            '/banner',
            '/history',
            `/history/${id}`,
            '/login',
            '/join',
            '/map',
            '/my',
            '/my/delete',
            '/my/edit',
            '/my/likes',
            '/my/review',
            '/notice',
            `/notice/${id}`,
            '/review',
            '/term-list',
            `/styles/${id}`,
            '/faq',
            '/qna',
            `/qna/${id}`,
            '/qna/write',
            `/banner/${id}`,
            '/login/forgot',
            '/license',
            '/result',
        ];
        const isNotTopBarPath = [
            '/login/email',
            '/join',
            '/around',
            '/join-designer',
            `/reservation/${id}`,
        ];
        const isNotBottomBarPath = [
            '/archive',
            `/archive/${id}`,
            '/banner',
            '/customers',
            `/history/${id}`,
            '/join',
            '/license',
            '/login',
            '/my/delete',
            '/my/edit',
            '/my/likes',
            '/my/review',
            '/notice',
            `/notice/${id}`,
            `/reservation/${id}`,
            '/rsv-designer',
            '/service-list',
            '/schedule',
            `/styles/${id}`,
            '/review',
            '/info-designer',
            '/join-designer',
            '/faq',
            '/qna',
            `/qna/${id}`,
            '/qna/write',
            `/banner/${id}`,
            '/login/email',
            '/login/forgot',
            `/designer/${id}`,
            '/term-list',
            `/my/review/${id}`,
            '/map',
        ];

        const isTitlePath = [
            { path: '/map', title: '지도로 위치 찾기' },
            { path: '/history', title: '내 예약' },
            // { path: '/my/likes', title: '찜한 디자이너' },
            // { path: '/my/review', title: '내가 작성한 리뷰'},
            { path: '/my', title: 'MY' },
            { path: '/notice', title: '공지사항' },
            { path: '/archive', title: '아카이브' },
            { path: '/term-list', title: '약관 내용 확인' },
            { path: '/discount', title: '할인' },
        ];
        const setLogo = isLogoPath.includes(location.pathname);
        const setRightMenu = !isNotRightMenuPath.includes(location.pathname);
        const setShowTopBar = !isNotTopBarPath.includes(location.pathname);
        const setShowBottomBar = !isNotBottomBarPath.includes(location.pathname);

        for (const item of isTitlePath) {
            if (item.path === location.pathname) {
                ss.setBarTitle(item.title);
                break;
            } else if (location.pathname.includes(item.path)) {
                ss.setBarTitle(item.title);
                break;
            }
        }

        ss.setIsLogo(setLogo);
        ss.setIsRightMenu(setRightMenu);
        ss.setshowTopNavBar(setShowTopBar);

        return () => {
            ss.terminate();
        };
    }, [navigate]);

    return (
        <>
            <AppStyle />
            <Frame>
                {/* <aside>
                    <img
                        style={{ maxWidth: 340, marginRight: 30 }}
                        src="https://images.pet-friends.co.kr/storage/pet_friends/tab_banner/1/3/5/c/0/4/c/135c04c7ed7229bafb82ce09d8abb7a5/10000/0cd00f9f9febd7fcefe74e343f043c7e.png"
                    />
                    <h3>머리할래 광고</h3>
                </aside> */}
                <Main>
                    {ss.showTopNavBar && <TopNavBar />}
                    <Aside />
                    {ss.lottie && (
                        <Lottie
                            config={{
                                loop: true,
                                autoplay: true,
                                animationData: animationData,
                            }}
                            style={lottieStyle}
                        />
                    )}
                    <Outlet />
                </Main>
            </Frame>
        </>
    );
}

const AppStyle = createGlobalStyle`
    html, body{
        height: 100%;
    }
    body{
        background-color: lavender;
    }
`;

const lottieStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '3.5rem',
    height: '3.5rem',
    transform: 'translate(-50%,-50%)',
    pointerEvents: 'none',
    zIndex: 99,
};
