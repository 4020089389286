import { create } from 'zustand';
import { emailRegex, passwordRegex } from '@/utils/helper';
const initialState = {
    registRole: '',
    screenOrder: ['term', 'name', 'phone', 'user_id', 'password', 'nickname', 'salon', 'confirm'],
    validatesOrder: [
        'term',
        'name',
        'phone',
        'user_id',
        'confirm_password',
        'nickname',
        'salon',
        'confirm',
    ],
    fields: {
        user_id: '',
        name: '',
        nickname: '',
        phone: '',
        password: '',
        confirm_password: '',
        salonName: '',
        salon: '',
        shop_id: null,
        isSearched: false,
    },
    terms: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
    },
    verify: {
        isVerified: false,
        isInputNumber: false,
        isCheckedPhone: '',
        verifyCode: '',
        isCheckedId: '',
    },
    validations: {
        term: false,
        name: false,
        phone: false,
        user_id: false,
        password: false,
        confirm_password: false,
        nickname: true,
        salon: false,
        confirm: true,
        isSearched: false,
    },
    popUpStates: {
        mismatchCode: false,
        timeout: false,
        cancelSignUp: false,
        searchSalon: false,
        joinComplete: false,
        verifyError: false,
        requestShop: false,
        addressError: false,
    },
    currentField: 'term',
    currentValidate: 'term',
    screenStates: {
        term: true,
        name: false,
        phone: false,
        user_id: false,
        password: false,
        address: false,
        nickname: false,
        salon: false,
    },
    makeShop: {
        name: null,
        address: null,
        address_detail: '',
        isSearched: false,
        isMarker: false,
    },
};

const validateField = (fieldName, text, state) => {
    // 각 필드에 대한 유효성 검사
    switch (fieldName) {
        case 'term':
            return state[0] && state[1] && state[2] && state[3];
        case 'name':
            // name 필드에 대한 유효성 검사
            return text.length > 0;
        case 'phone':
            return state.verify.isVerified;
        case 'password':
            return passwordRegex.test(text);
        case 'confirm_password':
            return text === state.fields.password && passwordRegex.test(state.fields.password);
        case 'user_id':
            return emailRegex.test(text) && state.verify.isCheckedId === true;
        case 'salon':
            return state.fields.shop_id != null;

        default:
            return true;
    }
};

const useJoinStore = create((set) => ({
    ...initialState,
    setRegistRole: (role) => {
        set((state) => {
            const updatedFields = { ...state.fields };
            const updatedValidations = { ...state.validations };
            let updatedScreenOrder = [...state.screenOrder];
            let updatedValidatesOrder = [...state.validatesOrder];

            if (role === 'designer') {
                updatedFields.salon = '';
                updatedValidations.salon = false;

                // designer일 때 salon 필드를 추가하고 한 단계 앞으로 이동
                if (!updatedScreenOrder.includes('salon')) {
                    updatedScreenOrder.splice(updatedScreenOrder.indexOf('confirm'), 0, 'salon');
                    updatedValidatesOrder.splice(
                        updatedValidatesOrder.indexOf('confirm'),
                        0,
                        'salon'
                    );
                }
            } else {
                delete updatedFields.salon;
                delete updatedValidations.salon;

                // designer가 아닐 때 salon 필드를 제거
                updatedScreenOrder = updatedScreenOrder.filter((field) => field !== 'salon');
                updatedValidatesOrder = updatedValidatesOrder.filter((field) => field !== 'salon');
            }

            return {
                registRole: role,
                fields: updatedFields,
                validations: updatedValidations,
                screenOrder: updatedScreenOrder,
                validatesOrder: updatedValidatesOrder,
            };
        });
    },
    setField: (fieldName, text) => {
        set((state) => {
            let updatedFields = { ...state.fields };
            let updatedValidations = { ...state.validations };

            updatedFields[fieldName] = text;

            if (fieldName === 'password') {
                updatedValidations.confirm_password =
                    text === state.fields.confirm_password &&
                    passwordRegex.test(state.fields.confirm_password);
            }

            if (fieldName === 'shop_id') {
                updatedValidations.salon = validateField('salon', text, {
                    ...state,
                    fields: updatedFields,
                });
            }

            updatedValidations[fieldName] = validateField(fieldName, text, {
                ...state,
                fields: updatedFields,
            });

            return {
                fields: updatedFields,
                validations: updatedValidations,
            };
        });
    },
    setMakeShop: (field, value) => {
        set((state) => ({
            makeShop: {
                ...state.makeShop,
                [field]: value,
            },
        }));
    },
    toggleTerm: (termIdx) => {
        set((state) => {
            const updatedTerms = { ...state.terms };
            const updatedValidations = { ...state.validations };

            if (termIdx === 4) {
                const allTermsTrue = Object.values(updatedTerms).every((term) => term === true);
                for (let key in updatedTerms) {
                    updatedTerms[key] = !allTermsTrue;
                }
            } else {
                updatedTerms[termIdx] = !state.terms[termIdx];

                const areAllPreviousTrue = [0, 1, 2, 3].every((key) => updatedTerms[key] === true);
                updatedTerms[4] = areAllPreviousTrue;
            }

            updatedValidations['term'] = validateField('term', '', {
                0: updatedTerms[0],
                1: updatedTerms[1],
                2: updatedTerms[2],
                3: updatedTerms[3],
            });

            return {
                terms: updatedTerms,
                validations: updatedValidations,
            };
        });
    },
    togglePopUp: (popUpName) => {
        set((state) => ({
            popUpStates: {
                ...state.popUpStates,
                [popUpName]: !state.popUpStates[popUpName],
            },
        }));
    },
    closeAllPopUps: () => {
        set({
            popUpStates: {
                mismatchCode: false,
                timeout: false,
                cancelSignUp: false,
                searchSalon: false,
                joinComplete: false,
                verifyed: false,
            },
        });
    },
    setVerify: (key, value) => {
        set((state) => ({
            verify: {
                ...state.verify,
                [key]: value,
            },
        }));
    },
    verifyValidate: () => {
        set((state) => {
            let updatedValidations = { ...state.validations };

            updatedValidations.phone = validateField('phone', '', state);

            return {
                validations: updatedValidations,
            };
        });
    },
    chkIdValidate: () => {
        set((state) => {
            let updatedValidations = { ...state.validations };

            updatedValidations.user_id = validateField('user_id', state.fields.user_id, state);

            return {
                validations: updatedValidations,
            };
        });
    },
    setCurrentField: (fieldName) => {
        set({ currentField: fieldName });
    },

    setCurrentValidate: (fieldName) => {
        set({ currentValidate: fieldName });
    },

    setScreenStates: (fieldName, value) => {
        set((state) => ({
            screenStates: {
                ...state.screenStates,
                [fieldName]: value,
            },
        }));
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useJoinStore;
