import { useRoutes, Navigate } from 'react-router-dom';
import Layout from '@/screens/common/Layout';
import ManagerRoute from './ManagerRoute';
import Home from '@/screens/common/Home';
import DesignerDetail from '@/screens/designer/DesignerDetail';
import Review from '@/screens/user/Review';
import MyPageEdit from '@/screens/user/MyPageEdit';
import NoticeList from '@/screens/board/NoticeList';
import TermList from '@/screens/common/TermList';
import LoginEmail from '@/screens/common/LoginEmail';
import Notice from '@/screens/board/Notice';
import Numbers from '@/screens/common/Numbers';
import NewShop from '@/screens/shop/NewShop';
import EditShop from '@/screens/shop/EditShop';
import EditDealer from '@/screens/dealer/EditDealer';
import NewDealer from '@/screens/dealer/NewDealer';

function Router() {
    let routeResult = useRoutes([
        {
            path: '/',
            element: <Layout />,
            children: [
                {
                    path: '/',
                    element: (
                        <ManagerRoute>
                            <Home />
                        </ManagerRoute>
                    ),
                },
                {
                    path: '/designers/:id',
                    element: (
                        <ManagerRoute>
                            <DesignerDetail />
                        </ManagerRoute>
                    ),
                },
                {
                    path: '/review',
                    element: (
                        <ManagerRoute>
                            <Review />
                        </ManagerRoute>
                    ),
                },
                {
                    path: '/my',
                    element: (
                        <ManagerRoute>
                            <MyPageEdit />
                        </ManagerRoute>
                    ),
                },
                {
                    path: '/notice',
                    element: (
                        <ManagerRoute>
                            <NoticeList />
                        </ManagerRoute>
                    ),
                },
                {
                    path: '/notice/:id',
                    element: (
                        <ManagerRoute>
                            <Notice />
                        </ManagerRoute>
                    ),
                },
                {
                    path: '/term-list',
                    element: <TermList />,
                },
                {
                    path: '/login',
                    element: <LoginEmail />,
                },
                {
                    path: '/shops/new',
                    element: (
                        <ManagerRoute>
                            <NewShop />
                        </ManagerRoute>
                    ),
                },
                {
                    path: '/shops/:id/edit',
                    element: (
                        <ManagerRoute>
                            <EditShop />
                        </ManagerRoute>
                    ),
                },
                {
                    path: '/dealers/new',
                    element: (
                        <ManagerRoute>
                            <NewDealer />
                        </ManagerRoute>
                    ),
                },
                {
                    path: '/dealers/:id/edit',
                    element: (
                        <ManagerRoute>
                            <EditDealer />
                        </ManagerRoute>
                    ),
                },
                {
                    path: '/numbers',

                    element: (
                        <ManagerRoute>
                            <Numbers />
                        </ManagerRoute>
                    ),
                },
            ],
        },
        { path: '*', element: <Navigate to="/" /> },
    ]);
    return routeResult;
}

export default Router;
