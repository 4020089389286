import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import {
    Mb30,
    BtnDelete,
    PhotoItem,
    GridImg,
    Spacing20,
    PhotoGrid,
    FileFullLabel,
    StickyBtm,
    TextBoxCount,
    TextBoxWrapper,
    Textbox,
    Pad20,
    PopShortMsg,
    BtnFullModal,
    Fz15Bold,
    PadVert30,
    Container,
    ContainerPadding,
    Fz15,
    Mb20,
    NavHeight,
    InputHidden,
    TxCent,
} from '@/css/style';
import { useReviewCreateMutation } from '@/services/reviewService';
import useReviewStore from '@/stores/user/review';
import NewModal from '@/components/NewModal';
import icClear from '@/assets/img/ic_clear.svg';
import icPhoto from '@/assets/img/ic_photo.svg';

function Review() {
    const rs = useReviewStore();
    const navigate = useNavigate();
    const ReviewCreateMutation = useReviewCreateMutation(() => rs.toggleModal('confirm'));

    const requestReview = () => {
        if (Object.values(rs.validations).every((val) => val === true)) {
            ReviewCreateMutation.mutate(rs.fields);
        } else {
            return;
        }
    };

    useEffect(() => {
        !rs.fields.reservId && navigate(-1);
        return () => {
            rs.terminate();
        };
    }, []);
    return (
        <>
            <Container className="main-container">
                <NavHeight />
                <ContainerPadding>
                    <PadVert30 as={TxCent}>
                        <Fz15Bold $col="#000">시술에 만족하셨나요?</Fz15Bold>
                        <Spacing20 />
                        <Rating
                            onClick={(rating) => {
                                rs.setField('rating', rating);
                            }}
                            allowFraction={true}
                            fillColor="#FFBB00"
                            emptyColor="#E8EAED"
                            size="2rem"
                        />
                    </PadVert30>
                    <Mb20>
                        <TextBoxWrapper>
                            <Textbox
                                id="content"
                                name="content"
                                rows="8"
                                maxLength="1000"
                                placeholder="시술평을 최소 10자 이상 입력해 주세요"
                                onChange={(e) => {
                                    const { value } = e.target;
                                    rs.setField('content', value);
                                }}
                                value={rs.fields.content}
                            ></Textbox>
                            <TextBoxCount>
                                {rs.fields.content.length
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                /1,000
                            </TextBoxCount>
                        </TextBoxWrapper>
                    </Mb20>
                    <Mb30>
                        <FileFullLabel>
                            <InputHidden
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    rs.addImage(e);
                                }}
                            />
                            <img src={icPhoto} /> <Fz15Bold>사진 첨부하기</Fz15Bold>
                        </FileFullLabel>
                    </Mb30>
                    <PhotoGrid>
                        {rs.fields.pic.map((image, index) => (
                            <PhotoItem key={index}>
                                <GridImg src={image.fileURL} />
                                <BtnDelete onClick={() => rs.deleteImage(index)}>
                                    <img src={icClear} alt="삭제" />
                                </BtnDelete>
                            </PhotoItem>
                        ))}
                    </PhotoGrid>
                </ContainerPadding>
            </Container>
            <StickyBtm>
                <BtnFullModal
                    $active={Object.values(rs.validations).every((val) => val === true)}
                    onClick={requestReview}
                >
                    작성완료
                </BtnFullModal>
            </StickyBtm>
            {/* 리뷰 작성 완료 모달 */}
            <NewModal isOpen={rs.modal.confirm} contentLabel="리뷰 작성 완료" $visible>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">리뷰작성이 완료되었습니다</Fz15Bold>
                        </Mb20>
                        <Fz15>
                            작성된 리뷰는 MY 페이지에서
                            <br />
                            확인 가능합니다.
                        </Fz15>
                    </PopShortMsg>
                    <BtnFullModal
                        $active
                        onClick={() => {
                            navigate('/my', { replace: true });
                        }}
                    >
                        확인
                    </BtnFullModal>
                </Pad20>
            </NewModal>
            {/* //리뷰 작성 완료 모달 */}
        </>
    );
}

export default Review;
