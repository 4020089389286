import axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Navigate, useNavigate } from 'react-router-dom';
import { instance } from '@services/api';

const boardService = {
    archiveListRead: () => {
        return instance.get('/boards/archive');
    },
    archiveRead: (id) => {
        return instance.get(`/boards/archive/${id}`);
    },
    noticeListRead: () => {
        return instance.get('/boards/notice?categories=manager');
    },
    noticeRead: (id) => {
        return instance.get(`/boards/notice/${id}`);
    },
};

export const useArchiveListReadQuery = () => {
    return useQuery({
        queryKey: ['archiveListRead', 'useArchiveListReadQuery'],
        queryFn: () => boardService.archiveListRead(),
    });
};

export const useArchiveReadQuery = (id) => {
    return useQuery({
        queryKey: ['archiveRead', id],
        queryFn: () => boardService.archiveRead(id),
    });
};

export const useNoticeListReadQuery = () => {
    return useQuery({
        queryKey: ['noticeListRead', 'useNoticeListReadQuery'],
        queryFn: () => boardService.noticeListRead(),
    });
};

export const useNoticeReadQuery = (id) => {
    return useQuery({
        queryKey: ['noticeRead', id],
        queryFn: () => boardService.noticeRead(id),
    });
};
