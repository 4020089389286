import { create } from 'zustand';

const initialState = {
    fields: {},
};

const useMyPageStore = create((set) => ({
    ...initialState,

    setField: (obj) => {
        set((state) => ({
            fields: {
                ...state.fields,
                ...obj,
            },
        }));
    },

    terminate: () => {
        set(initialState);
    },
}));

export default useMyPageStore;
