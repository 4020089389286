import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Spacing70,
    FlxbtFullMb50,
    Container,
    ContainerPadding,
    BtnLogin2,
    FlxMiddle,
    JoinField,
    PadVert10,
    InputWrapper,
    JoinInput,
    Fz15Bold,
    Fz15,
    Fz30Bold,
    WarnInput,
    ChkAndTxt,
    AbsolBtm,
    Pad20,
    Paragraph,
    LineCenter,
    SnsLoginTit,
    FlxRight,
    Mb50,
    PopShortMsg,
    BtnFullModal,
    Mb20,
} from '@/css/style';
import { onlyEmail } from '../../utils/helper';
import { useLoginMutation } from '@/services/authService';
import { Google, Kakao, Naver } from '@/components/OAuth';
import useJoinStore from '@/stores/common/join';
import useLoginStore from '@/stores/common/login';
import useUserStore from '@/stores/user/user';
import Chkbox from '@/components/Chkbox';
import NewModal from '@/components/NewModal';
import icScissor from '@/assets/img/ic_scissor.svg';
import icWarn from '@/assets/img/ic_warn.svg';
import { ColAccent } from '../../css/style';

function LoginEmail() {
    const ls = useLoginStore();
    const js = useJoinStore();
    const us = useUserStore();
    const navigate = useNavigate();
    const login = useLoginMutation(us, () => ls.setIsError(true));
    const [emailDatalist, setEmailDatalist] = useState([]);

    useEffect(() => {
        return () => {
            ls.terminate();
        };
    }, []);

    useEffect(()=>{
        const emails = [
            'naver.com',
            'gmail.com',
            'daum.net',
            'kakao.com',
            'hanmail.net',
            'nate.com',
            'outlook.com',
            'hotmail.com',
            'icloud.com'
        ]

        if (ls.fields?.user_id?.length > 0 && ls.fields?.user_id?.indexOf('@') < 0) {
            setEmailDatalist(emails.map(emailDomain=>`${ls.fields.user_id}@${emailDomain}`));
        } else {
            setEmailDatalist([]);
        }
    }, [ls.fields])

    const validate = ls.fields.user_id && ls.fields.password;
    const requestLogin = () => {
        if (!validate) {
            return;
        } else {
            login.mutate(ls.fields);
        }
    };

    return (
        <>
            <Container className="main-container">
                <Spacing70 />
                <ContainerPadding>
                    <form>
                        <Fz30Bold $mb="3.077rem">LOGIN</Fz30Bold>
                        <JoinField>
                            <PadVert10>
                                <Fz15Bold>아이디</Fz15Bold>
                            </PadVert10>
                            <InputWrapper>
                                <JoinInput
                                    type="text"
                                    id="userId"
                                    name="userId"
                                    maxLength="30"
                                    value={ls.fields.user_id}
                                    placeholder="아이디를 입력해 주세요"
                                    onChange={(e) =>
                                        ls.setField('user_id', onlyEmail(e.target.value))
                                    }
                                />
                                {emailDatalist.length > 0 && <div className="user_id_datalist">
                                    <ul>
                                    {
                                        emailDatalist.map(email=><li key={email} onClick={()=>ls.setField('user_id', email)}>{email}</li>)
                                    }
                                    </ul>
                                </div>}
                            </InputWrapper>
                            {ls.isError && (
                                <WarnInput>
                                    <img src={icWarn} alt="경고" /> 아이디를 확인해 주세요.
                                </WarnInput>
                            )}
                        </JoinField>
                        <JoinField $mb="1.884rem">
                            <PadVert10>
                                <Fz15Bold>비밀번호</Fz15Bold>
                            </PadVert10>
                            <InputWrapper>
                                <JoinInput
                                    type="password"
                                    id="userPw"
                                    name="userPw"
                                    value={ls.fields.password}
                                    placeholder="비밀번호를 입력해 주세요"
                                    onChange={(e) => ls.setField('password', e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            requestLogin();
                                        }
                                    }}
                                />
                            </InputWrapper>

                            {ls.isError && (
                                <WarnInput>
                                    <img src={icWarn} alt="경고" /> 비밀번호를 확인해 주세요.
                                </WarnInput>
                            )}
                        </JoinField>
                        {/* <FlxRight as={Mb50}>
                            <FlxMiddle>
                                <Link to="/login/forgot">
                                    <Fz15>ID/PW 찾기</Fz15>
                                </Link>
                                &nbsp;・&nbsp;
                                <Link to="/join" onClick={() => js.setRegistRole('user')}>
                                    <Fz15>회원가입</Fz15>
                                </Link>
                            </FlxMiddle>
                        </FlxRight> */}
                        <BtnLogin2
                            $active={validate}
                            type="button"
                            onClick={() => {
                                requestLogin();
                            }}
                        >
                            로그인
                        </BtnLogin2>
                    </form>
                    <AbsolBtm $wd="100%" $bt="4.4615rem" style={{ left: 0 }}>
                        <Paragraph $fz="1.154rem" $ta="center">
                            매니저 회원 등록을 원하시거나
                            <br />
                            관리자 로그인 정보를 분실 하셨을 경우
                            <br />
                            고객센터 <ColAccent>1566-1986</ColAccent>으로 연락 주시기 바랍니다.
                            <br />
                            (통화 가능 시간 : 평일 오전 10시~ 오후 6시 )
                        </Paragraph>
                    </AbsolBtm>
                </ContainerPadding>
            </Container>
        </>
    );
}

export default LoginEmail;
