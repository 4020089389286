import React, { useEffect } from 'react';
import {
    JoinInputPlc15,
    BtnHalfModalBlack,
    BtnHalfModal,
    JoinField,
    InputWrapper,
    Fz15Bold,
    Mb10,
    Container,
    Fz18Medium,
    StickyBtm,
    ContainerPadding,
    ListCol2,
    ColRed,
} from '@/css/style';
import { useNavigate } from 'react-router-dom';
import useUserStore from '@/stores/user/user';
import { toast } from 'react-toastify';
import useDealerStore from '../../stores/dealer/dealer';
import { useDealerCreateMutation } from '../../services/dealerService';

function NewDealer() {
    const navigate = useNavigate();
    const ds = useDealerStore();
    const us = useUserStore();

    useEffect(() => {
        ds.setField({ manager: us.user.id });
        return () => {
            ds.terminate();
        };
    }, []);

    const DealerCreateMutation = useDealerCreateMutation();

    const handleSubmit = () => {
        if (ds.fields.name === '') {
            toast.error('이름을 입력해주세요.');
            return;
        }

        if (ds.fields.phone === '') {
            toast.error('전화번호를 입력해주세요.');
            return;
        }

        DealerCreateMutation.mutate(ds.fields);
    };

    return (
        <>
            <Container className="main-container">
                <ContainerPadding>
                    <Fz18Medium $mb="2.3077rem">신규 등록</Fz18Medium>
                    <JoinField $mb="3.846rem">
                        <Mb10>
                            <Fz15Bold>
                                딜러 이름 <ColRed>*</ColRed>
                            </Fz15Bold>
                        </Mb10>
                        <InputWrapper>
                            <JoinInputPlc15
                                type="text"
                                maxLength="15"
                                placeholder="이름 입력"
                                value={ds.fields.name}
                                onChange={(e) => {
                                    ds.setField({ name: e.target.value });
                                }}
                            />
                        </InputWrapper>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb10>
                            <Fz15Bold>
                                딜러 전화번호 <ColRed>*</ColRed>
                            </Fz15Bold>
                        </Mb10>
                        <InputWrapper>
                            <JoinInputPlc15
                                type="text"
                                maxLength="15"
                                placeholder="전화번호 입력"
                                value={ds.fields.phone}
                                onChange={(e) => {
                                    ds.setField({ phone: e.target.value });
                                }}
                            />
                        </InputWrapper>
                    </JoinField>
                </ContainerPadding>
                <StickyBtm>
                    <ListCol2>
                        <BtnHalfModalBlack
                            type="button"
                            onClick={() => navigate('/', { replace: true })}
                        >
                            취소
                        </BtnHalfModalBlack>
                        <BtnHalfModal onClick={handleSubmit}>등록</BtnHalfModal>
                    </ListCol2>
                </StickyBtm>
            </Container>
        </>
    );
}

export default NewDealer;
