import React, { useEffect, useState } from 'react';
import {
    FlxbtFullMb10,
    JoinInputPlc15,
    BtnHalfModalBlack,
    BtnHalfModal,
    GridImg,
    StyleList,
    StyleItem,
    PhotoItem,
    TextBoxWrapper,
    Textbox,
    TextBoxCount,
    SelectSnsOptions,
    SelectSnsCurrent,
    SelectSns,
    Mb20,
    BtnInInputRound,
    ShopHoursItem,
    JoinField,
    InputWrapper,
    Fz15Bold,
    Select,
    Mb10,
    Container,
    Fz18Medium,
    StickyBtm,
    ContainerPadding,
    ListCol2,
    Fz15,
    ColRed,
    FlxbtFull,
    SelectSnsImg,
    BtnDelete,
    InputHidden,
    FileSquareLabel,
    Pad20,
    PopShortMsg,
    FlxMiddle,
    DaumWrapper,
} from '@/css/style';
import config from '@config';
import {
    useDesignerDetailQuery,
    useDesignerImageAddMutation,
    useDesignerImageDeleteMutation,
    useDesignerProfileAddMutation,
    useDesignerUpdateMutation,
} from '@/services/designerService';
import { useNavigate } from 'react-router-dom';
import useUserStore from '@/stores/user/user';
import NewModal from '@/components/NewModal';
import icClear from '@/assets/img/ic_clear.svg';
import snsFb from '@/assets/img/sns_fb.svg';
import snsIg from '@/assets/img/sns_ig.svg';
import snsX from '@/assets/img/sns_x.svg';
import snsYt from '@/assets/img/sns_ytube.svg';
import DaumPostcodeEmbed from 'react-daum-postcode';
import useShopStore from '@/stores/shop/shop';
import useDynamicRefs from 'use-dynamic-refs';
import { useShopCreateMutation } from '../../services/shopService';
import { toast } from 'react-toastify';
import { emailRegex } from '../../utils/helper';

function NewShop() {
    const navigate = useNavigate();
    const ss = useShopStore();
    const us = useUserStore();
    const [getRef, setRef] = useDynamicRefs();
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

    useEffect(() => {
        console.log(us.user);
        ss.setField({ manager_id: us.user.id, manager_name: us.user.name });

        ss.setOpeningHour({
            monday: 'open',
            monday_from_time: '20',
            monday_to_time: '40',
            tuesday: 'open',
            tuesday_from_time: '20',
            tuesday_to_time: '40',
            wednesday: 'open',
            wednesday_from_time: '20',
            wednesday_to_time: '40',
            thursday: 'open',
            thursday_from_time: '20',
            thursday_to_time: '40',
            friday: 'open',
            friday_from_time: '20',
            friday_to_time: '40',
            saturday: 'open',
            saturday_from_time: '20',
            saturday_to_time: '40',
            sunday: 'open',
            sunday_from_time: '20',
            sunday_to_time: '40',
        });

        return () => {
            ss.terminate();
        };
    }, []);

    const ShopCreateMutation = useShopCreateMutation();

    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        ss.setField({
            address: data.address,
            address_detail: extraAddress,
        });
    };

    const handleSubImageChange = (e) => {
        const files = e.target.files;
        const filesArray = Array.from(files);
        const filteredFiles = filesArray.filter((file) => {
            return ss.file.sub.every((item) => item.name !== file.name);
        });
        ss.setFile('sub', filteredFiles);
    };

    const handleSubImageDelete = (targetFile) => {
        const filteredFiles = ss.file.sub.filter((file) => {
            return file.name !== targetFile.name;
        });
        ss.setFile('sub', filteredFiles);
    };

    const renderMainPhoto = (source) => {
        return (
            <PhotoItem>
                <GridImg src={URL.createObjectURL(source)} alt="" key={source.name} />
                <BtnDelete>
                    <img src={icClear} alt="삭제" onClick={(e) => ss.setFile('main', null)} />
                </BtnDelete>
            </PhotoItem>
        );
    };

    const renderSubPhotos = (source) => {
        return Array.from(source).map((file, index) => {
            return (
                <StyleItem key={index}>
                    <PhotoItem>
                        <GridImg
                            src={URL.createObjectURL(file)}
                            alt={`sub_file_${index}`}
                            key={file.name}
                        />
                        <BtnDelete>
                            <img
                                src={icClear}
                                alt="삭제"
                                onClick={(e) => handleSubImageDelete(file)}
                            />
                        </BtnDelete>
                    </PhotoItem>
                </StyleItem>
            );
        });
    };

    const handleSubmit = () => {
        if (ss.fields.name === '') {
            toast.error('상호명을 입력해주세요.');
            return;
        }

        if (ss.fields.address === '') {
            toast.error('주소를 입력해주세요.');
            return;
        }

        if (ss.fields.email !== '' && !emailRegex.test(ss.fields.email)) {
            toast.error('이메일을 확인해 주세요.');
            return;
        }

        let dayOffErrorMsg = '';
        const updatedOpeningHours = [];
        let isBreak = false;

        for (let i = 0; i < ss.dayList.length; i++) {
            if (ss.opening_hour_set[ss.dayList[i].day] === 'open') {
                if (
                    ss.opening_hour_set[`${ss.dayList[i].day}_from_time`] === '' ||
                    ss.opening_hour_set[`${ss.dayList[i].day}_to_time`] === ''
                ) {
                    dayOffErrorMsg += `${ss.dayList[i].label}요일의 운영시간을 입력해 주세요.\n`;
                    scrollToRef(getRef(`${ss.dayList[i].day}_section`));
                    isBreak = true;
                    break;
                } else if (
                    parseInt(ss.opening_hour_set[`${ss.dayList[i].day}_from_time`]) >=
                    parseInt(ss.opening_hour_set[`${ss.dayList[i].day}_to_time`])
                ) {
                    dayOffErrorMsg += `${ss.dayList[i].label}요일의 운영시작시간이 종료시간보다 늦을 수 없습니다.\n`;
                    scrollToRef(getRef(`${ss.dayList[i].day}_section`));
                    isBreak = true;
                    break;
                }

                const newEntry = {
                    day: ss.dayList[i].day,
                    type: 'open',
                    from_time:
                        ss.timeTable[ss.opening_hour_set[`${ss.dayList[i].day}_from_time`]] ||
                        '10:00',
                    to_time:
                        ss.timeTable[ss.opening_hour_set[`${ss.dayList[i].day}_to_time`]] ||
                        '20:00',
                };

                updatedOpeningHours.push(newEntry);
            } else {
                const newEntry = {
                    day: ss.dayList[i].day,
                    type: 'dayoff',
                    from_time: '',
                    to_time: '',
                };

                updatedOpeningHours.push(newEntry);
            }
        }

        if (dayOffErrorMsg !== '') {
            toast.error(dayOffErrorMsg);
            return;
        }

        if (isBreak) {
            return;
        }

        ss.setFieldOpeningHour({ opening_hour: updatedOpeningHours });

        if(ss.file.main){
            ss.setField({ main_file: ss.file.main });
        }

        if(ss.file.sub){
            ss.setField({ files: ss.file.sub  });
        }

        ss.setIsSubmit(true);
    };

    // 상태 업데이트 완료 후에 비동기 작업 수행
    useEffect(() => {
        ss.isSubmit && ShopCreateMutation.mutate(ss.fields);
    }, [ss.isSubmit]);

    return (
        <>
            <Container className="main-container">
                <ContainerPadding>
                    <Fz18Medium $mb="2.3077rem">신규 등록</Fz18Medium>
                    <JoinField $mb="3.846rem">
                        <Mb10>
                            <Fz15Bold>매장 상호 (필수)</Fz15Bold>
                        </Mb10>
                        <InputWrapper>
                            <JoinInputPlc15
                                type="text"
                                maxLength="15"
                                placeholder="예)머리할래 강남점"
                                value={ss.fields.name}
                                onChange={(e) => {
                                    ss.setField({ name: e.target.value });
                                }}
                            />
                        </InputWrapper>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb10>
                            <Fz15Bold>주소 (필수)</Fz15Bold>
                        </Mb10>
                        <InputWrapper as={Mb10}>
                            <JoinInputPlc15
                                type="text"
                                placeholder="예) 판교역로 235, 한남동714"
                                value={ss.fields.address}
                                readOnly
                            />
                            <BtnInInputRound onClick={() => ss.toggleModal('address')}>
                                주소 검색
                            </BtnInInputRound>
                            {ss.modal.address && (
                                <DaumWrapper>
                                    <DaumPostcodeEmbed onComplete={handleComplete} />
                                </DaumWrapper>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <JoinInputPlc15
                                type="text"
                                placeholder="상세 주소"
                                value={ss.fields.address_detail}
                                onChange={(e) => {
                                    ss.setField({ address_detail: e.target.value });
                                }}
                            />
                        </InputWrapper>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb10>
                            <Fz15Bold>매장 대표번호 (선택)</Fz15Bold>
                        </Mb10>
                        <InputWrapper>
                            <JoinInputPlc15
                                type="text"
                                maxLength="15"
                                placeholder="전화번호 입력"
                                value={ss.fields.tel}
                                onChange={(e) => {
                                    ss.setField({ tel: e.target.value });
                                }}
                            />
                        </InputWrapper>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb10>
                            <Fz15Bold>대표 이메일 (선택)</Fz15Bold>
                        </Mb10>
                        <FlxbtFull>
                            <InputWrapper style={{ width: '100%' }}>
                                <JoinInputPlc15
                                    type="text"
                                    // maxLength="15"
                                    placeholder="이메일 입력"
                                    value={ss.fields.email}
                                    onChange={(e) => {
                                        ss.setField({ email: e.target.value });
                                    }}
                                />
                            </InputWrapper>
                        </FlxbtFull>
                    </JoinField>

                    <JoinField $mb="3.846rem">
                        <Mb20>
                            <Fz15Bold>대표 사진 등록 (선택)</Fz15Bold>
                        </Mb20>
                        <Mb20>매장 소개와 함께 등록됩니다. 가로 이미지를 사용해 주세요.</Mb20>
                        <StyleList>
                            {ss.file.main ? (
                                <StyleItem>{renderMainPhoto(ss.file.main)}</StyleItem>
                            ) : (
                                <StyleItem>
                                    <FileSquareLabel>
                                        <InputHidden
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => {
                                                ss.setFile('main', e.target.files[0]);
                                            }}
                                        />
                                    </FileSquareLabel>
                                </StyleItem>
                            )}
                        </StyleList>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb20>
                            <Fz15Bold>기타 추가 이미지 등록 (선택)</Fz15Bold>
                        </Mb20>
                        <Mb20>한 번에 최대 30장 이하 등록 가능합니다.</Mb20>
                        <StyleList>
                            {ss.file.sub.length > 0 && renderSubPhotos(ss.file.sub)}
                            <StyleItem>
                                <FileSquareLabel>
                                    <InputHidden
                                        type="file"
                                        accept="image/*"
                                        onChange={handleSubImageChange}
                                        multiple
                                    />
                                </FileSquareLabel>
                            </StyleItem>
                        </StyleList>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb20>
                            <Fz15Bold>매장 소개 글 (선택)</Fz15Bold>
                        </Mb20>
                        <TextBoxWrapper>
                            <Textbox
                                rows="8"
                                maxLength="1000"
                                placeholder="앱에 노출되는 정보입니다. 매장을 소개하는 글을 입력해 주세요."
                                onChange={(e) => ss.setField({ introduce: e.target.value })}
                                value={ss.fields.introduce}
                            ></Textbox>
                            <TextBoxCount>
                                {ss.fields.introduce?.length
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                /5,000
                            </TextBoxCount>
                        </TextBoxWrapper>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb20>
                            <Fz15Bold>예약 시 주의 사항 공통 항목 (선택)</Fz15Bold>
                        </Mb20>
                        <TextBoxWrapper>
                            <Textbox
                                rows="8"
                                maxLength="1000"
                                placeholder="예약시 주의 해야 하는 내용을 입력해 주세요."
                                onChange={(e) =>
                                    ss.setField({ reservation_notice: e.target.value })
                                }
                                value={ss.fields.reservation_notice}
                            ></Textbox>
                            <TextBoxCount>
                                {ss.fields.reservation_notice?.length
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                /1,000
                            </TextBoxCount>
                        </TextBoxWrapper>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb20>
                            <Fz15Bold>매장 편의 서비스 (선택)</Fz15Bold>
                        </Mb20>
                        <TextBoxWrapper>
                            <Textbox
                                rows="8"
                                maxLength="1000"
                                placeholder="매장에 대한 공통 편의 서비스를 입력해 주세요."
                                onChange={(e) => ss.setField({ services: e.target.value })}
                                value={ss.fields.services}
                            ></Textbox>
                            <TextBoxCount>
                                {ss.fields.services?.length
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                /1,000
                            </TextBoxCount>
                        </TextBoxWrapper>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb20>
                            <Fz15Bold>매장 운영 시간 (필수)</Fz15Bold>
                        </Mb20>
                        {ss.dayList.map((item, index) => (
                            <ShopHoursItem ref={setRef(`${item.day}_section`)} key={index}>
                                <InputWrapper style={{ maxWidth: '4.7rem' }}>
                                    <Select
                                        ref={setRef(item.day)}
                                        onChange={(e) => {
                                            ss.setOpeningHour({
                                                [item.day]: e.target.value,
                                                [`${item.day}_from_time`]: '',
                                                [`${item.day}_to_time`]: '',
                                            });
                                        }}
                                        // defaultValue="open"
                                    >
                                        <option value="open">시간</option>
                                        <option value="dayoff">휴무</option>
                                    </Select>
                                </InputWrapper>
                                <Fz15 $col="#000">{item.label}</Fz15>
                                {ss.opening_hour_set[item.day] === 'open' && (
                                    <FlxMiddle>
                                        <InputWrapper>
                                            <Select
                                                ref={setRef(`${item.day}_from_time`)}
                                                onChange={(e) =>
                                                    ss.setOpeningHour({
                                                        [`${item.day}_from_time`]: e.target.value,
                                                    })
                                                }
                                            >
                                                <option>Open time</option>
                                                {ss.timeTable.map((time, index) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={index}
                                                            selected={index === 20}
                                                        >
                                                            {time}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                        </InputWrapper>
                                        <span>-</span>
                                        <InputWrapper>
                                            <Select
                                                ref={setRef(`${item.day}_to_time`)}
                                                onChange={(e) =>
                                                    ss.setOpeningHour({
                                                        [`${item.day}_to_time`]: e.target.value,
                                                    })
                                                }
                                            >
                                                <option>Close time</option>
                                                {ss.timeTable.map((time, index) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={index}
                                                            selected={index === 40}
                                                        >
                                                            {time}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                        </InputWrapper>
                                    </FlxMiddle>
                                )}
                            </ShopHoursItem>
                        ))}
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb10>
                            <Fz15Bold>네이버블로그 (선택)</Fz15Bold>
                        </Mb10>
                        <FlxbtFull>
                            <InputWrapper style={{ width: '100%' }}>
                                <JoinInputPlc15
                                    type="text"
                                    placeholder="매장 네이버블로그 주소입력"
                                    value={ss.fields.naverblog}
                                    onChange={(e) => {
                                        ss.setField({ naverblog: e.target.value });
                                    }}
                                />
                            </InputWrapper>
                        </FlxbtFull>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb10>
                            <Fz15Bold>인스타그램 (선택)</Fz15Bold>
                        </Mb10>
                        <FlxbtFull>
                            <InputWrapper style={{ width: '100%' }}>
                                <JoinInputPlc15
                                    type="text"
                                    placeholder="매장 인스타그램 주소입력"
                                    value={ss.fields.instagram}
                                    onChange={(e) => {
                                        ss.setField({ instagram: e.target.value });
                                    }}
                                />
                            </InputWrapper>
                        </FlxbtFull>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb10>
                            <Fz15Bold>유튜브 (선택)</Fz15Bold>
                        </Mb10>
                        <FlxbtFull>
                            <InputWrapper style={{ width: '100%' }}>
                                <JoinInputPlc15
                                    type="text"
                                    placeholder="매장 유튜브 주소입력"
                                    value={ss.fields.youtube}
                                    onChange={(e) => {
                                        ss.setField({ youtube: e.target.value });
                                    }}
                                />
                            </InputWrapper>
                        </FlxbtFull>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb10>
                            <Fz15Bold>페이스북 (선택)</Fz15Bold>
                        </Mb10>
                        <FlxbtFull>
                            <InputWrapper style={{ width: '100%' }}>
                                <JoinInputPlc15
                                    type="text"
                                    placeholder="매장 페이스북 주소입력"
                                    value={ss.fields.facebook}
                                    onChange={(e) => {
                                        ss.setField({ facebook: e.target.value });
                                    }}
                                />
                            </InputWrapper>
                        </FlxbtFull>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb10>
                            <Fz15Bold>카카오채널 (선택)</Fz15Bold>
                        </Mb10>
                        <FlxbtFull>
                            <InputWrapper style={{ width: '100%' }}>
                                <JoinInputPlc15
                                    type="text"
                                    placeholder="매장 카카오채널 주소입력"
                                    value={ss.fields.kakaochannel}
                                    onChange={(e) => {
                                        ss.setField({ kakaochannel: e.target.value });
                                    }}
                                />
                            </InputWrapper>
                        </FlxbtFull>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb10>
                            <Fz15Bold>다음카페 (선택)</Fz15Bold>
                        </Mb10>
                        <FlxbtFull>
                            <InputWrapper style={{ width: '100%' }}>
                                <JoinInputPlc15
                                    type="text"
                                    placeholder="매장 다음카페 주소입력"
                                    value={ss.fields.daumcafe}
                                    onChange={(e) => {
                                        ss.setField({ daumcafe: e.target.value });
                                    }}
                                />
                            </InputWrapper>
                        </FlxbtFull>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb10>
                            <Fz15Bold>네이버 예약 생성 ID (선택)</Fz15Bold>
                        </Mb10>
                        <InputWrapper>
                            <JoinInputPlc15
                                type="text"
                                maxLength="15"
                                placeholder="네이버 예약 생성 ID 입력"
                            />
                        </InputWrapper>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb10>
                            <Fz15Bold>네이버 API KEY (선택)</Fz15Bold>
                        </Mb10>
                        <InputWrapper>
                            <JoinInputPlc15
                                type="text"
                                maxLength="15"
                                placeholder="API KEY (해당 정보는 확인 후 변경 가능)"
                            />
                        </InputWrapper>
                    </JoinField>
                </ContainerPadding>
                <StickyBtm>
                    <ListCol2>
                        <BtnHalfModalBlack
                            type="button"
                            onClick={() => navigate('/', { replace: true })}
                        >
                            취소
                        </BtnHalfModalBlack>
                        <BtnHalfModal onClick={handleSubmit}>등록</BtnHalfModal>
                    </ListCol2>
                </StickyBtm>
            </Container>
            <NewModal $visible>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">매장 정보가 저장되었습니다.</Fz15Bold>
                        </Mb20>
                    </PopShortMsg>
                    <ListCol2>
                        {/* <BtnHalfModalBlack onClick={() => is.toggleModal()}>닫기</BtnHalfModalBlack> */}
                        <BtnHalfModal onClick={() => navigate('/', { replace: true })}>
                            홈으로
                        </BtnHalfModal>
                    </ListCol2>
                </Pad20>
            </NewModal>
        </>
    );
}
export default NewShop;
