import React from 'react';
import { BtnSnsLogin } from '@/css/style';
import icKakao from '@/assets/img/ic_kakao.svg';
import icNaver from '@/assets/img/ic_naver.svg';
import icApple from '@/assets/img/ic_apple.svg';

const createOAuthComponent = (img, provider, clientId, authUrl, bgcolor) => {
    const handleLogin = () => {
        window.location.href = `${authUrl}`;
    };

    return () => (
        <BtnSnsLogin onClick={handleLogin} $bg={bgcolor}>
            <img src={img} alt={provider} />
        </BtnSnsLogin>
    );
};

const Google = createOAuthComponent(
    icApple,
    '애플',
    process.env.REACT_APP_GOOGLE_CLIENT_ID,
    `https://accounts.google.com/o/oauth2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=http://localhost:3000/oauth&response_type=code&scope=openid`,
    '#000',
    '#fff'
);

const Kakao = createOAuthComponent(
    icKakao,
    '카카오톡으',
    process.env.REACT_APP_KAKAO_CLIENT_ID,
    `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=http://localhost:3000/oauth&response_type=code`,
    '#F9E001',
    '#424242'
);

const Naver = createOAuthComponent(
    icNaver,
    '네이버',
    process.env.REACT_APP_NAVER_CLIENT_ID,
    `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&state=false&redirect_uri=http://localhost:3000/oauth`,
    '#2DB400',
    '#fff'
);

export { Google, Kakao, Naver };
