import { instance } from '@services/api';
import {
    useQuery,
    useMutation,
    QueryClient,
    useQueryClient,
    useInfiniteQuery,
} from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const managerService = {
    getDesigner: (page, params) => {
        return instance.get('/designers', {
            params: {
                page: page,
                ...params,
            },
        });
    },
    getDesignerDetail: (designerId, managerId) => {
        return instance.get(`/managers/${managerId}/designers/${designerId}`);
    },
    getShop: (page, params) => {
        return instance.get('/shops', {
            params: {
                page: page,
                ...params,
            },
        });
    },
    getDealer: (page, params, managerId) => {
        return instance.get(`/managers/${managerId}/dealers`, {
            params: {
                page: page,
                ...params,
            },
        });
    },
    approveDesigner: (designerId) => {
        return instance.put(`/designers/${designerId}/approve`);
    },
    disapproveDesigner: (designerId) => {
        return instance.put(`/designers/${designerId}/disapprove`);
    },
    activeDesigner: (data) => {
        return instance.put(`/managers/${data.managerId}/designers/${data.designerId}/active`);
    },
    inactiveDesigner: (data) => {
        return instance.put(`/managers/${data.managerId}/designers/${data.designerId}/inactive`);
    },
    updateDesignerDealer: (data) => {
        return instance.put(`/managers/${data.managerId}/designers/${data.designerId}/dealer`, data);
    },
    getManager: (managerId) => {
        return instance.get(`/managers/${managerId}`);
    },
    updateManager: (data) => {
        return instance.put(`/managers/${data.manager}`, data);
    },
    getSubscription: (page, params, managerId) => {
        return instance.get(`/managers/${managerId}/subscriptions`, {
            params: {
                page: page,
                ...params,
            },
        });
    },
    getSubscriptionSummary: (params, managerId) => {
        return instance.get(`/managers/${managerId}/subscription-summary`, {
            params: { ...params },
        });
    },
    getCommission: (page, params, managerId) => {
        return instance.get(`/managers/${managerId}/commissions`, {
            params: {
                page: page,
                ...params,
            },
        });
    }
};

// export const useDesignerDetailQuery = (id) => {
//     return useQuery({
//         queryKey: ['designerDetail', id],
//         queryFn: () => designerService.designerDetail(id),
//         retry: 0,
//         gcTime: 0,
//     });
// };

// export const useDesignerDetailForAsideQuery = (id, role) => {
//     return useQuery({
//         queryKey: ['designerDetail', id],
//         queryFn: () => designerService.designerDetail(id),
//         retry: 0,
//         gcTime: 0,
//         enabled: id !== '' && role === 'DESIGNER',
//     });
// };

export const useGetDesignerQuery = (params) => {
    return useInfiniteQuery({
        queryKey: ['designer', params.status],
        queryFn: ({ pageParam }) => managerService.getDesigner(pageParam, params),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.data.totalPages > lastPage.data.page) {
                return lastPage.data.page + 1;
            } else {
                return null;
            }
        },
    });
};

export const useDesignerDetailQuery = (designerId, managerId) => {
    return useQuery({
        queryKey: ['designerDetail', designerId],
        queryFn: () => managerService.getDesignerDetail(designerId, managerId),
    });
}

export const useGetShopQuery = (params) => {
    return useInfiniteQuery({
        queryKey: ['shop', params.approved],
        queryFn: ({ pageParam }) => managerService.getShop(pageParam, params),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.data.totalPages > lastPage.data.page) {
                return lastPage.data.page + 1;
            } else {
                return null;
            }
        },
    });
};

export const useGetDealerQuery = (params, managerId) => {
    return useInfiniteQuery({
        queryKey: ['dealer', managerId],
        queryFn: ({ pageParam }) => managerService.getDealer(pageParam, params, managerId),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.data.totalPages > lastPage.data.page) {
                return lastPage.data.page + 1;
            } else {
                return null;
            }
        },
    });
};

export const useApproveDesignerMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (id) => {
            const response = await managerService.approveDesigner(id);
            return response;
        },
        onSuccess: async (res) => {
            toast.success('승인으로 변경되었습니다.');
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useDisapproveDesignerMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (id) => {
            const response = await managerService.disapproveDesigner(id);
            return response;
        },
        onSuccess: async (res) => {
            toast.success('심사로 변경되었습니다.');
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useActiveDesignerMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (data) => {
            const response = await managerService.activeDesigner(data);
            return response;
        },
        onSuccess: async (res) => {
            toast.success('활성화로 변경되었습니다.');
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
}

export const useInactiveDesignerMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (data) => {
            const response = await managerService.inactiveDesigner(data);
            return response;
        },
        onSuccess: async (res) => {
            toast.success('비활성화로 변경되었습니다.');
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
}

export const useUpdateDesignerDealerMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (data) => {
            const response = await managerService.updateDesignerDealer(data);
            return response;
        },
        onSuccess: async (res) => {
            toast.success('딜러가 변경되었습니다.');
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
            toast.error('딜러 변경에 실패했습니다.');
        },
    });
}

export const useGetManagerQuery = (managerId) => {
    return useQuery({
        queryKey: ['getManager', managerId],
        queryFn: () => managerService.getManager(managerId),
    });
};

export const useUpdateManagerMutation = (onSuccess) => {
    const navigate = useNavigate();
    return useMutation({
        mutationFn: async (data) => {
            const response = await managerService.updateManager(data);
            return response;
        },
        onSuccess: async (res) => {
            toast.success('정보가 수정되었습니다.');
            navigate('/', { replace: true });
        },
        onError: async (error) => {
            toast.error(error.message);
        },
    });
};

export const useGetSubscriptionQuery = (params, managerId) => {
    console.log('params', params);
    return useInfiniteQuery({
        queryKey: ['subscription', params],
        queryFn: ({ pageParam }) => managerService.getSubscription(pageParam, params, managerId),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.data.totalPages > lastPage.data.page) {
                return lastPage.data.page + 1;
            } else {
                return null;
            }
        },
    });
};

export const useGetSubscriptionSummaryQuery = (params, managerId) => {
    return useQuery({
        queryKey: ['subscriptionSummary', params],
        queryFn: () => managerService.getSubscriptionSummary(params, managerId),
    });
};

export const useGetCommissionQuery = (params, managerId) => {
    return useInfiniteQuery({
        queryKey: ['commission', managerId],
        queryFn: ({ pageParam }) => managerService.getCommission(pageParam, params, managerId),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.data.totalPages > lastPage.data.page) {
                return lastPage.data.page + 1;
            } else {
                return null;
            }
        },
    });
};
